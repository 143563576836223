import React, { useState, useEffect } from 'react';
import {
IconSearch,
IconBackspace,
} from '@tabler/icons-react';
import Input from '../Input/Input';
import FilterMenuExpand from './FilterMenuExpand';
import SmartScroll from '../SmartScroll/SmartScroll';

const MultiSelectSubFilter = ({subFilters: initialSubFilters, onSelectSubFilter, onBackButtonClick, categoryFilter, icon, info, filterTitle, ...props}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [subFiltersState, setSubFiltersState] = useState(initialSubFilters);
    
    const filteredSubFilters = searchTerm 
        ? subFiltersState.filter(filter => 
            filter.filterTitle.toLowerCase().includes(searchTerm.toLowerCase()) || 
            filter.subFilters?.some(subFilter => 
                subFilter?.filterTitle?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        )
        : subFiltersState;

    const handleFilterChange = (updatedFilter, index) => {
        setSubFiltersState(prev => {
            const newFilters = [...prev];
            newFilters[index] = updatedFilter;
            
            // Pass the updated state to parent in the requested format
            onSelectSubFilter && onSelectSubFilter({
                [filterTitle]: newFilters
            });
            
            return newFilters;
        });
    };
        
    return <React.Fragment>
        <div data-testid="sub-filter-search-container" className="py-2 px-3 border-b border-black-100 mx-2">
            <Input
                data-testid="sub-filter-search-input"
                icon={<IconSearch className='text-black-300' size={16} stroke={2} data-testid="sub-filter-search-icon" />}
                placeholder="Search tags"
                aria-label="Search tags"
                className="w-full bg-transparent border-none gap-1 text-sm"
                inputClassName="w-full"
                onChange={(value) => setSearchTerm(value)}
            />
        </div>
        <SmartScroll
            className="px-2.5 py-0 flex-grow overflow-hidden"
            data-testid="product-profile-details-scrollbar"
        >
        <div data-testid="sub-filters-list">
            {
                filteredSubFilters.length > 0 ? (
                    filteredSubFilters.map((subFilter, index) => (
                        <FilterMenuExpand 
                            key={`${subFilter.filterTitle}-${index}`}
                            data-testid={`sub-filter-item-${index}`}
                            filterTitle={subFilter.filterTitle}
                            count={subFilter.count}
                            id={subFilter.id}
                            color={subFilter.color}
                            icon={icon}
                            searchTerm={searchTerm}
                            subFilters={subFilter.subFilters}
                            onFilterChange={(data) => handleFilterChange(data, index)}
                            parentId={subFilter.id}
                            parentChecked={subFilter.checked}
                        />
                    ))
                ) : (
                    <div data-testid="no-results-message" className="p-4 text-center text-sm text-black-400">
                        {searchTerm ? "No filters match your search" : "No filters available"}
                    </div>
                )
            }
        </div>
        </SmartScroll>
        {
            info ?
            <div 
                data-testid="back-button-container"
                className={`flex items-center py-2.5 px-3 my-1 mx-2 gap-2 border-t border-black-100 ${categoryFilter ? "cursor-default" : "hover:bg-black-50 cursor-pointer"}`} 
                onClick={() => {
                    if (!categoryFilter) {
                        onBackButtonClick();
                    }
                }}
            >
                <IconBackspace size={20} stroke={1.5} className='text-black-400' data-testid="back-icon" />
                <button 
                    data-testid="non-matching-tags-button"
                    className={`text-xs font-medium text-black-400 text-left ${categoryFilter || true ? "cursor-default" : ""}`}
                >
                    {info}
                </button>
            </div>
            :
            <div data-testid="non-matching-tags-info" className="py-1" />
        }
    </React.Fragment>;
};

export default MultiSelectSubFilter;
