import React from "react";

const SelectList = ({icon, selectList, onClick, ...props}) => {
    return (
        <div 
            className="flex flex-col py-1 rounded-md bg-white border border-black-100 z-10 absolute top-[25px] left-[6px]" 
            data-testid="select-list-container"
            {...props}
        >
            {
                selectList.map((item, index) => (
                    <div 
                        key={index} 
                        className="flex items-center hover:bg-black-50 px-3 py-1 group" 
                        onClick={() => onClick(item.label)}
                        data-testid={`select-list-item-${index}`}
                    >
                        {item.icon ? 
                            <span data-testid={`select-list-item-icon-${index}`}>
                                {item.icon}
                            </span> 
                            : null
                        }
                        <span 
                            className="w-max text-xs text-black-400 group-hover:text-black-500 group-hover:font-semibold" 
                            data-testid={`select-list-item-label-${index}`}
                        >
                            {item.label}
                        </span>
                    </div>
                ))
            }
        </div>
    );
};

export default SelectList;
