import React, { useState, useEffect } from "react";
import { IconChevronDown  } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const MenuExpand = ({icon, link, menu, submenu, headerExpand, setHeaderExpand, type, selected, setSelected, setSubmenu, subMenuName, setSubMenuName, ...props}) => {

    const [expandMenu, setExpandMenu] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        if(type===selected && expandMenu && submenu?.length) {
            setExpandMenu(true);
            setSubmenu(true);
        } else {
            setExpandMenu(false);
            setSubmenu(false);
        }
        if(!headerExpand) {
            setExpandMenu(false);
            setSubmenu(false);
        }
    }, [headerExpand]);

    useEffect(() => {
        if(selected !== type) {
            setExpandMenu(false);
        }
    }, [selected]);
    
    return (
        <div data-testid={`menu-expand-${type}`} className="flex flex-col justify-start">
            <div 
                data-testid={`menu-item-${type}`}
                className={`w-full py-2 rounded-md flex items-center gap-2 px-1 cursor-pointer ${!expandMenu ? "group" : ""} ${selected === type && !expandMenu ? "bg-cyan-50 hover:bg-cyan-50" : "hover:bg-black-50"} ${!headerExpand ? "justify-center" : ""}`}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if(headerExpand && submenu?.length) {
                        setExpandMenu(!expandMenu);
                        setSubmenu(!expandMenu);
                    } else {
                        setHeaderExpand(true);
                        if(submenu?.length) {
                            setExpandMenu(true);
                        }
                    }
                    setSelected(type);
                    if(submenu === undefined && link) {
                        setSubMenuName(null);
                    } else if(headerExpand && !submenu?.length) {
                        setHeaderExpand(false);
                    }
                }}
            >
                <div data-testid={`menu-icon-${type}`} className="flex items-center justify-center">
                    {icon}
                </div>
                {
                    headerExpand ?
                    <div data-testid={`menu-text-container-${type}`} className="flex items-center gap-2">
                        <span data-testid={`menu-text-${type}`} className={`text-sm font-bold ${selected === type && !expandMenu ? "text-cyan-600 group-hover:text-cyan-600" : "text-black-400"}`}>{menu}</span>
                        {
                            submenu?.length ?
                            <IconChevronDown data-testid={`menu-chevron-${type}`} stroke={2.5} className={`h-4 w-4 ${expandMenu ? "rotate-180" : ""} ${selected === type && !expandMenu ? "text-cyan-600 group-hover:text-cyan-600" : "text-black-400"}`}/>
                            : null
                        }
                    </div>
                    : null
                }
            </div>
            {
                headerExpand && expandMenu && submenu?.length ?
                <React.Fragment>
                    {
                        submenu.map((sm, index) => {
                            return(
                                <div 
                                    key={`submenu-${type}-${index}`}
                                    data-testid={`submenu-item-${type}-${sm.label.replace(/\s+/g, '-').toLowerCase()}`}
                                    className={`w-full py-2 rounded flex items-center gap-2 pl-4 px-1 cursor-pointer group ${subMenuName === sm.label ? "bg-cyan-50 hover:bg-cyan-50" : "hover:bg-black-50"}`} 
                                    onClick={() => {
                                        setHeaderExpand(false);
                                        if(sm?.link) {
                                            setSubMenuName(sm.label, type);
                                            navigate({pathname: sm.link, search: ''})
                                        }
                                    }}
                                    
                                >
                                    <div data-testid={`submenu-icon-${type}-${sm.label.replace(/\s+/g, '-').toLowerCase()}`} className="flex items-center justify-center">
                                        {sm.icon}
                                    </div>
                                    <div data-testid={`submenu-text-container-${type}-${sm.label.replace(/\s+/g, '-').toLowerCase()}`} className="flex items-center gap-2">
                                        <span data-testid={`submenu-text-${type}-${sm.label.replace(/\s+/g, '-').toLowerCase()}`} className={`text-sm ${subMenuName === sm.label ? "text-cyan-600 group-hover:text-cyan-600" : "text-black-400"}`}>{sm.label}</span>
                                    </div>
                                </div>
                            );
                        })
                    }
                </React.Fragment>
                : null
            }
        </div>
    );
}

export default MenuExpand;
