import React from "react";
import { IconCircleCheckFilled, IconExclamationCircleFilled, IconCircleXFilled, IconX } from '@tabler/icons-react';

let alertPopTimer = null;
const AlertMessage = (props) => {
    
    if(props.show !== 'info') {
        if(alertPopTimer) clearTimeout(alertPopTimer);
        alertPopTimer = setTimeout(() => {
            props.onClose();
        }, (props.displayDuration || 5000));
    }

    return ( props.show ?
        <div className={`absolute bottom-[25px] right-[25px] z-[9999] rounded-[8px]`} data-testid="alert-message">
            <div className={`flex items-center gap-4 p-2.5 bg-success shadow-md animate-fade-in-up rounded-[8px] ${props.status === "success" ? "bg-cyan-50" : "bg-red-50"}`} key={`user-report-notify-${props.status}`} data-testid="alert-message-container">
                {
                    props.status === 'success'  ?
                    <IconCircleCheckFilled className="text-black-900 h-[28px] w-[28px]" data-testid="alert-message-icon-success"/>
                    :
                    props.status === 'warning' ? 
                    <IconExclamationCircleFilled className="text-black-900 h-[28px] w-[28px]" data-testid="alert-message-icon-warning"/>
                    :
                    props.status === 'info' ? 
                    <IconExclamationCircleFilled className="text-black-900 h-[28px] w-[28px] rotate-180" data-testid="alert-message-icon-info"/>
                    :
                    <IconCircleXFilled className="text-black-900 h-[28px] w-[28px]" data-testid="alert-message-icon-error"/>
                } 
                <div className={`flex flex-col justify-start font-normal text-sm leading-tight text-black-900`} data-testid="alert-message-content-wrapper">
                    {/* <span className="font-semibold">{props.title ? props.title : props.status === 'success' ? 'Success' : props.status === 'warning' ? 'Warning' : props.status === 'info' ? 'Information' : 'Error'}</span> */}
                    <div data-testid="alert-message-content">{props.content}</div>
                </div>
                {
                    props.closeIcon &&
                    <IconX 
                        className="text-black-900 h-[16px] w-[16px] opacity-75 hover:opacity-100 cursor-pointer"
                        stroke={3}
                        onClick={(e) => {
                            e.stopPropagation();
                            if(alertPopTimer) clearTimeout(alertPopTimer);
                            props.onClose();
                        }}
                        data-testid="alert-message-close-btn"
                    />
                }
            </div>
        </div>
        :
        <></>
    )
}

export default AlertMessage;