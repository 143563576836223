import React, { useState, useEffect } from 'react';
import NoDataFound from "../NoDataFound/NoDataFound";
import ProductProfileList from './ProductProfileList';
import AlertBox from '../AlertBox/AlertBox';
import ActivityPopup from '../ActivityPopup/ActivityPopup';
import ProductProfileModify from './ProductProfileModify';
import ProductProfileDetails from './ProductProfileDetails';
import { SignedIn } from '@clerk/clerk-react';
import {
IconCircleCheckFilled,
IconTemperature,
IconTag,
IconBuildingSkyscraper
} from '@tabler/icons-react';
import { useSession, useUser, useOrganization } from '@clerk/clerk-react';
import { debounce } from 'lodash';
import { createClerkSupabaseClientProduct, createClerkSupabaseClientLandingZone } from "../../utility/utility";
import AlertMessage from '../AlertMessage/AlertMessage';
import { getRangeLabel } from "../../utility/utility";
import { useParams, useNavigate } from 'react-router-dom';

const ProductProfile = ({ ...props }) => {

    const { user } = useUser();
    const { session } = useSession();
    const { memberships } = useOrganization({
        memberships: {
          infinite: true, // Append new data to the existing list
          keepPreviousData: true, // Persist the cached data until the new data has been fetched
        },
    });
    const { productId } = useParams();
    const navigate = useNavigate();

    const [designConfig, setDesignConfig] = useState({
        addView: false,
        detailsView: true,
        deleteView: false,
        activityView: false,
        warningView: false
    });
    const [productProfileActivity, setProductProfileActivity] = useState([]);
    const [filters, setFilters] = useState({
        "Organizations": {
            icon: <IconBuildingSkyscraper size={16} className="w-4 h-4 text-black-500" stroke={2} />,
            hide: true,
            data: []
        },
        "Tags": {
            icon: <IconTag size={16} className="w-4 h-4 text-black-500" stroke={2} />,
            data: []
        }
    });
    const [productList, setProductList] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [tempSelectedProductId, setTempSelectedProductId] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [productActivityLoading, setProductActivityLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [strRanges, setStrRanges] = useState([]);
    const [alertMessage, setAlertMessage] = useState({
        show: false,
        message: "Successfully created product profile",
        type: "success"
    });
    const [userList, setUserList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [userId, updatedUserId] = useState("");
    const [loggedInUser, updateLoggedInUser] = useState({});
    const [clearSearchAndFilters, setClearSearchAndFilters] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [filterArr, setFilterArr] = useState([]);
    const [productDetailsCopy, setProductDetailsCopy] = useState(null);

    const client = createClerkSupabaseClientProduct(session);

    useEffect(() => {
        if(memberships?.data?.length > 0 && !userList.length) {
            setUserList(memberships.data);
            let userData =  memberships.data?.filter(d => d.publicUserData?.userId === userId)[0];
            updateLoggedInUser(userData);
        }
    }, [memberships]);

    useEffect(() => {
        if (!user || !session) return;

        if(user) {
            updatedUserId(user.id);

            const orgList = user?.organizationMemberships?.map(org => ({
                id: org.organization.id,
                name: org.organization.name
            }));

            setOrganizationList(orgList);
            fetchTagsList();
        }
        
        // Only fetch data once when user and session are available
        if (!isInitialized) {
            fetchProductProfiles('', [], true);
            fetchStrRanges();
            setIsInitialized(true);
        }
    }, [user, session]);

    useEffect(() => {
        if (!organizationList.length || !productList.length) return;
        
        const orgProductCount = updateOrgProductCount(productList);
        if(organizationList?.length > 1){
            setFilters({
                ...filters,
                "Organizations": {
                    icon: <IconBuildingSkyscraper size={16} className="w-4 h-4 text-black-500" stroke={2} />,
                    hide: false,
                    data: organizationList?.map(org => ({
                        filterTitle: org.name,
                        id: org.id,
                        count: orgProductCount[org.id] ? orgProductCount[org.id] : 0,
                    }))
                }
            });
        }
    }, [productList, organizationList]);

    useEffect(() => {
        if (tagsList.length > 0) {
            const tagsData = formatFilteredTagsData(tagsList, productList);

            let unAssignedCount = calcUnAssignedTags(productList, tagsList);
            setFilters({
                ...filters,
                "Tags": {
                    icon: <IconTag size={16} className="w-4 h-4 text-black-500" stroke={2} />,
                    data: tagsData?.Tags?.data,
                    hide: false,
                    info: `${unAssignedCount} tags are not matching any issue`
                }
            });
        }
    }, [tagsList, productList]);

    useEffect(() => {
        // Handle browser back/forward navigation
        const handlePopState = () => {
            const pathParts = window.location.pathname.split('/');
            const urlProductId = pathParts[pathParts.length - 1];
            
            if (urlProductId && urlProductId !== 'product-profiles' && productList.length > 0) {
                const product = productList.find(p => p.id === urlProductId);
                if (product) {
                    // Update both the selected ID and product details
                    setSelectedProductId(urlProductId);
                    setProductDetails(product);
                    
                    // Reset any edit/add states if they were active
                    setDesignConfig({
                        addView: false,
                        detailsView: true,
                        deleteView: false,
                        activityView: false,
                        warningView: false
                    });
                }
            } else {
                // If we're at the base route and have products, select the first one
                if (productList.length > 0) {
                    setSelectedProductId(productList[0].id);
                    setProductDetails(productList[0]);
                }
            }
        };

        window.addEventListener('popstate', handlePopState);
        
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [productList]); // Add productList as dependency

    useEffect(() => {
        if (productDetails?.product_name) {
            // Find the Header component and update its feature name
            const headerElement = document.querySelector('[data-testid="header-bar"]');
            if (headerElement) {
                const featureElement = headerElement.querySelector('[data-testid="header-feature-title"]');
                if (featureElement) {
                    featureElement.textContent = designConfig.addView ? "Add Product Profile" : productDetails.product_name;
                }
            }
        }
    }, [productDetails, designConfig]);

    const updateOrgProductCount = (pList) => {
        const orgCountMap = {};
        
        pList.forEach(product => {
            if (product.org_id) {
                orgCountMap[product.org_id] = (orgCountMap[product.org_id] || 0) + 1;
            }
        });
        
        return orgCountMap;
    }

    const updateTagsProductCount = (productList) => {
        const tagCountMap = {};
        
        productList.forEach(product => {
            if (product.assigned_tags && Array.isArray(product.assigned_tags)) {
                product.assigned_tags.forEach(tag => {
                    const tagId = tag.tag_id;
                    tagCountMap[tagId] = (tagCountMap[tagId] || 0) + 1;
                });
            }
        });
        
        return tagCountMap;
    };

    const formatFilteredTagsData = (tagsList, pList) => {
        // Group tags by group_name
        const groupedTags = {};
        const ungroupedTags = [];

        const tagsProductCount = updateTagsProductCount(pList);
        
        tagsList?.forEach(tag => {
            if (tag?.group_name) {
                if (!groupedTags[tag.group_name]) {
                    groupedTags[tag.group_name] = [];
                }
                groupedTags[tag.group_name].push(tag);
            } else {
                ungroupedTags.push(tag);
            }
        });
        
        // Transform into the required format
        const tagsData = {
            "Tags": {
                icon: <IconTag size={16} className="w-4 h-4 text-black-500" stroke={2} />,
                data: []
            }
        };
        
        // Add grouped tags
        Object.keys(groupedTags)?.forEach(groupName => {
            const group = groupedTags[groupName];
            
            tagsData.Tags.data.push({
                filterTitle: groupName,
                count: group?.reduce((total, tag) => total + (tagsProductCount[tag?.id] || 0), 0),
                color: group[0]?.color_code || "#000000",
                subFilters: group?.map(tag => ({
                    filterTitle: tag?.tag_name || "",
                    count: tagsProductCount[tag?.id] ? tagsProductCount[tag?.id] : 0,
                    id: tag?.id,
                    color: tag?.color_code
                }))
            });
        });
        
        // Add ungrouped tags if any exist
        if (ungroupedTags?.length > 0) {
            tagsData.Tags.data.push({
                filterTitle: "Tags (Ungrouped)",
                count: ungroupedTags?.reduce((total, tag) => total + (tagsProductCount[tag?.id] || 0), 0),
                color: ungroupedTags[0]?.color_code || "#000000", // Default color for ungrouped
                subFilters: ungroupedTags?.map(tag => ({
                    filterTitle: tag?.tag_name || "",
                    count: tagsProductCount[tag?.id] ? tagsProductCount[tag?.id] : 0,
                    id: tag?.id,
                    color: tag?.color_code
                }))
            });
        }
        
        return tagsData;
    };

    const fetchProductProfiles = async (searchQuery = '', filters = [], selectFirstProfile) => {
        if (!session) return;
        
        setLoading(true);

        try {
            let query = client.from('product_profiles').select().order('created_at', { ascending: true });
            if (searchQuery) {
                setSearchName(searchQuery);
                query = query.or(`product_name.ilike.%${searchQuery}%,product_description.ilike.%${searchQuery}%`);
            } else {
                setSearchName("");
            }

            if (filters && filters.length > 0) {
                setFilterArr(filters);
                filters.forEach(filter => {
                    const { category, filteringType, selectedFilterId } = filter;

                    if (category === 'Tags') {
                        if (filteringType === 'include any of' || filteringType === 'include') {
                            query = query.or(selectedFilterId.map(tagId => `assigned_tags.cs.[{"tag_id": "${tagId}"}]`).join(','));
                        } else if (filteringType === 'include all of') {
                            selectedFilterId.forEach(tagId => {
                                query = query.filter('assigned_tags', 'cs', `[{"tag_id": "${tagId}"}]`);
                            });
                        } else if (filteringType === 'exclude if any of' || filteringType === 'exclude') {
                            selectedFilterId.forEach(tagId => {
                                query = query.filter('assigned_tags', 'not.cs', `[{"tag_id": "${tagId}"}]`);
                            });
                        } else if (filteringType === 'exclude if all') {
                            query = query.not('assigned_tags', 'cs', `[${selectedFilterId.map(tagId => `{"tag_id": "${tagId}"}`).join(',')}]`);
                        }
                    } else if (category === 'Organizations') {
                        if (filteringType === 'is any of' || filteringType === 'is') {
                            query = query.in('org_id', selectedFilterId);
                        } else if (filteringType === 'is not') {
                            selectedFilterId.forEach(id => {
                                query = query.neq('org_id', id);
                            });
                        }
                    }
                });
            } else {
                setFilterArr([]);
            }

            const { data, error } = await query;
            if (error) {
                console.error('Error fetching product profiles:', error);
                return;
            } else {
                let _data = data?.length ? data?.reverse() : [];
                setProductList(_data);
                
                if (_data && _data.length > 0) {
                    const pathParts = window.location.pathname.split('/');
                    const urlProductId = pathParts[pathParts.length - 1];
                    let activeProductProfileIndex = _data.findIndex(pp => pp.id === urlProductId);
                    activeProductProfileIndex = activeProductProfileIndex >=0 && !selectFirstProfile ? activeProductProfileIndex : 0;

                    setSelectedProductId(_data[activeProductProfileIndex].id);
                    setProductDetails(_data[activeProductProfileIndex]);
                }
            }
        } catch (error) {
            console.error('Error in fetchProductProfiles:', error);
        } finally {
            setLoading(false);
        }
    };

    const calcUnAssignedTags = (data, tagsList) => {
        // Extract all assigned tag IDs from the data
        const allAssignedTagIds = data.reduce((acc, product) => {
            if (product.assigned_tags && Array.isArray(product.assigned_tags)) {
                const tagIds = product.assigned_tags.map(tag => tag.tag_id);
                return [...acc, ...tagIds];
            }
            return acc;
        }, []);

        // Get unique tag IDs using Set
        const uniqueTagIds = [...new Set(allAssignedTagIds)];

        const difference = tagsList.length - uniqueTagIds.length;
        return difference < 0 ? 0 : difference;
    }

    const deleteProductProfile = async (productId) => {
        if (!session) return;
    
        const client = createClerkSupabaseClientProduct(session);
        setLoading(true);

        await removeTagsFromProductProfile(productDetails?.assigned_tags);

        try {
            // Delete the product profile where id matches productId
            const { data, error } = await client
                .from('product_profiles')
                .delete()
                .eq('id', productId);
    
            if (error) {
                console.error('Error deleting product profile:', error);
                return;
            }

            removeSearchAndFilters();
            fetchProductProfiles('', [], true);
            setAlertMessage({
                show: true,
                message: "Successfully deleted product profile",
                type: "success"
            });
            
        } catch (error) {
            console.error('Error in deleteProductProfile:', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchStrRanges = async () => {
        if (!session) return;
        
        const client = createClerkSupabaseClientProduct(session);

        try {
            let query = client.from('str_ranges').select().order('order', { ascending: true });
            const { data, error } = await query;
            
            if (error) {
                console.error('Error fetching product str Ranges:', error);
                return;
            } else {
                let updatedData = data?.map(d => ({ 
                    value: d.id, 
                    label: d.range_name.toLowerCase() === 'custom' ? d.range_name : `${d.range_name} ${getRangeLabel(d.range_min, d.range_max)}`,
                    range_min: d.range_min,
                    range_max: d.range_max
                }));

                setStrRanges(updatedData);
            }
        } catch (error) {
            console.error('Error in fetchStrRanges:', error);
        }
    }

    const getProductActivity = async (productId) => {
        if (!session || !productId) return;
    
        const client = createClerkSupabaseClientProduct(session);
        setProductActivityLoading(true);
        
        try {
            const { data, error } = await client.from('audit_trails').select().eq('product_profile_id', productId).order('changed_at', { ascending: true });
            if (error) {
                console.error('Error fetching getProductActivity:', error);
                return;
            } else {
                let _data = data.length && data.reverse();
                setProductProfileActivity(_data);
            }
        } catch (error) {
            console.error('Error in getProductActivity:', error);
        } finally {
            setProductActivityLoading(false);
        }
    }

    const onSelectProduct = (id) => {
        setLoading(true);
        setSelectedProductId(id);
        let pDetails = productList.filter(product => product.id === id)[0];
        setProductDetails(pDetails);
        // Using replace: false to maintain history stack
        navigate(`/product-profiles/${id}`, { 
            replace: false,
            state: { productName: pDetails.product_name }
        });
        setLoading(false);
    }

    const removeTagsFromProductProfile = async (tagIds) => {
        if (!session) return;
    
        const client = createClerkSupabaseClientLandingZone(session);
    
        try {
            const { data, error } = await client.from('assigned_tags')
                .delete()
                .in('tag_id', tagIds.map(tag => tag.tag_id))
                .select();
    
            if (error) {
                console.error('Error in deleteTagsFromProductProfile:', error);
                return;
            }
    
        } catch (error) {
            console.error('Error in deleteTagsFromProductProfile:', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchTagsList = async (searchQuery = '') => {
        if (!session) return;
    
        const client = createClerkSupabaseClientLandingZone(session);
    
        try {
            let query = client.from('tags').select();
            if (searchQuery) {
                query = query.or(`tag_name.ilike.%${searchQuery}%`);
            }
    
            const { data, error } = await query;
            if (error) {
                console.error('Error fetching tags:', error);
                return;
            } else {
                setTagsList(data);
            }
        } catch (error) {
            console.error('Error in fetchTagsList:', error);
        } finally {

        }
    };

    const removeSearchAndFilters = () => {
        setClearSearchAndFilters(true);
        setTimeout(() => {
            setClearSearchAndFilters(false);
        }, 5000);
    }

    return (
        <SignedIn>
        <div className="h-[100%] box-border bg-black-50 p-4 pt-0 flex flex-col gap-4 overflow-hidden" data-testid="product-profile-container">
            <AlertMessage
                show={alertMessage.show}
                content={alertMessage.message}
                status={alertMessage.type}
                closeIcon
                onClose={() => {
                    setAlertMessage({
                        ...alertMessage,
                        show: false
                    })
                }}
                data-testid="product-profile-alert-message"
            />
            <div className="flex items-center gap-4 w-[100%] h-[100%]" data-testid="product-profile-content">
                <div className="w-[20%] h-[100%] rounded-md bg-white flex flex-col" data-testid="product-profile-sidebar">
                    <ProductProfileList
                        userData={loggedInUser}
                        organizationList={organizationList}
                        editView={designConfig.editView}
                        onAddProduct={() => {
                            if (!designConfig.addView) {
                                setDesignConfig({
                                    ...designConfig,
                                    addView: true,
                                    detailsView: false
                                });
                                let list = productList?.length ? [...productList] : [];
                                list.unshift({
                                    name: "New Product Profile",
                                    type1: [{ name: "---" }],
                                    type2: [{ name: "---" }, { name: "---" }]
                                });
                                setProductList(list);
                            }
                        }}
                        clearSearchAndFilters={clearSearchAndFilters}
                        loading={loading}
                        productList={productList}
                        selectedProductId={selectedProductId}
                        onSelectProduct={async (id) => {
                            if(designConfig.addView || designConfig.editView) {
                                setTempSelectedProductId(id);
                                setDesignConfig({
                                    ...designConfig,
                                    warningView: true
                                });
                            } else {
                                onSelectProduct(id);
                            }
                        }}
                        addProduct={designConfig.addView}
                        filters={filters}
                        data-testid="product-profile-list"
                        onSearchProduct={debounce((query, filters) => {
                            fetchProductProfiles(query, filters, true);
                        }, 750)}
                        onFilterProduct={(query, filters) => {
                            fetchProductProfiles(query, filters, true);
                        }}
                    />
                </div>
                <div className="w-[80%] h-[100%] rounded-md bg-white flex flex-col space-between" data-testid="product-profile-main-content">
                    {
                        (designConfig.addView || designConfig.editView) &&
                        <ProductProfileModify
                            strRanges={strRanges}
                            userData={loggedInUser}
                            organizationList={organizationList}
                            editView={designConfig.editView}
                            productsData={productDetails}
                            onProductCancel={() => {
                                if (designConfig.addView) {
                                    let list = productList?.length ? [...productList] : [];
                                    if(list.length > 0) {
                                        list.shift();
                                    }
                                    setProductList(list);
                                }
                                setDesignConfig({
                                    ...designConfig,
                                    addView: false,
                                    editView: false,
                                    detailsView: true
                                });
                            }}
                            onProductSave={() => {
                                setAlertMessage({
                                    show: true,
                                    message: designConfig.addView ? "Successfully created product profile" : "Successfully updated product profile",
                                    type: "success"
                                });
                                if(designConfig.addView) {
                                    removeSearchAndFilters();
                                }
                                const params = designConfig.addView 
                                    ? { searchQuery: '', filters: [], selectFirstProfile: true }
                                    : { searchQuery: searchName, filters: filterArr, selectFirstProfile: false };
                                
                                fetchProductProfiles(params.searchQuery, params.filters, params.selectFirstProfile);
                                setDesignConfig({
                                    ...designConfig,
                                    addView: false,
                                    editView: false,
                                    detailsView: true
                                });
                            }}
                            setAlertMessage={(alert) => {
                                setAlertMessage({
                                    show: true,
                                    message: alert.message,
                                    type: alert.type
                                });
                            }}
                            setProductDetailsCopy={(details) => setProductDetailsCopy(details)}
                            data-testid="product-profile-modify"
                        />
                    }
                    {
                        designConfig.detailsView && productList.length > 0 &&
                        <ProductProfileDetails
                            userData={loggedInUser}
                            loading={loading}
                            productsData={loading ? productDetailsCopy : productDetails}
                            organizationList={organizationList}
                            onClickEdit={() => {
                                setDesignConfig({
                                    ...designConfig,
                                    editView: true,
                                    detailsView: false
                                });
                            }}
                            onClickActivity={() => {
                                setDesignConfig({
                                    ...designConfig,
                                    activityView: true
                                });
                                getProductActivity(selectedProductId);
                            }}
                            onClickDelete={() => {
                                setDesignConfig({
                                    ...designConfig,
                                    deleteView: true
                                });
                            }}
                            data-testid="product-profile-details"
                        />
                    }
                    {
                        productList.length === 0 &&
                        <div className="flex flex-1 flex-col justify-center items-center" data-testid="product-profile-empty-state">
                            <NoDataFound title={searchName || filterArr.length ? "No data found" : "Add a new product"} />
                        </div>
                    }
                </div>
            </div>
            {
                designConfig.warningView &&
                <AlertBox
                    title={'You have unsaved changes.'}
                    message="Are you sure you want to leave this page? Your changes will be lost."
                    confirmBtnText="Confirm"
                    confirmBtnType="primary"
                    cancelBtnType="secondary-v3"
                    onClose={() => {
                        setTempSelectedProductId(null);
                        setDesignConfig({
                            ...designConfig,
                            warningView: false
                        });
                    }}
                    onConfirm={() => {
                        if (designConfig.addView) {
                            let list = productList?.length ? [...productList] : [];
                            if(list.length > 0) {
                                list.shift();
                            }
                            setProductList(list);
                        }
                        onSelectProduct(tempSelectedProductId);
                        setDesignConfig({
                            ...designConfig,
                            warningView: false,
                            addView: false,
                            editView: false,
                            detailsView: true
                        });
                    }}
                    data-testid="product-profile-delete-alert"
                />
            }
            {
                designConfig.deleteView &&
                <AlertBox
                    title={`Confirm deletion of product profile "${productDetails.product_name}"`}
                    message="Are you sure you want to delete the selected product profile? This action cannot be undone."
                    confirmBtnText="Delete Product Profile"
                    confirmBtnType="warning"
                    cancelBtnType="secondary-v3"
                    onClose={() => {
                        setDesignConfig({
                            ...designConfig,
                            deleteView: false
                        });
                    }}
                    onConfirm={() => {
                        deleteProductProfile(selectedProductId);
                        setDesignConfig({
                            ...designConfig,
                            deleteView: false
                        });
                    }}
                    data-testid="product-profile-delete-alert"
                />
            }
            {
                designConfig.activityView &&
                <ActivityPopup
                    activityData={productProfileActivity}
                    paramMapping={{
                        "assigned_tags": ["tag_name"]
                    }}
                    userList={userList}
                    onActivityClose={(e) => {
                        if(e.target.closest('[data-testid="activity-popup-panel"]') && !e.target.closest('[data-testid="activity-popup-close-btn"]')) return;
                        setDesignConfig({
                            ...designConfig,
                            activityView: false
                        });
                    }}
                    loading={productActivityLoading}
                    data-testid="product-profile-activity-popup"
                />
            }
        </div>
        </SignedIn>
    );
};

export default ProductProfile;