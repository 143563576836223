import React from "react";
import { OrganizationList, OrganizationProfile, Protect } from '@clerk/clerk-react';
import SmartScroll from "../SmartScroll/SmartScroll";

const Organization =  () => {

    return <SmartScroll
        className="p-2.5 pt-0 flex-grow h-full overflow-hidden"
        data-testid="product-profile-details-scrollbar"
    >
        <div className="container px-4 py-8 max-w-[100%]">
            <div className="flex flex-row gap-8">
            {/* Left sidebar with organization list */}
            <Protect
                permission="org:sys_domains:manage"
                fallback={<></>}
            >
                <OrganizationList
                    hidePersonal={true}
                    appearance={{
                    elements: {                  
                    }
                    }}
                />
            </Protect>
            {/* Main content area with organization profile */}
            <OrganizationProfile className="flex-grow"/>
            </div>
        </div>
    </SmartScroll>;
}

export default Organization;