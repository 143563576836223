import React from "react";
import {
IconCircleCheckFilled,
IconPencil, 
IconHistory, 
IconTrash, 
IconBracketsContain,
IconTag
} from '@tabler/icons-react';
import Tag from "../Tag/Tag";
import ToolTip from "../ToolTip/ToolTip";
import SmartScroll from "../SmartScroll/SmartScroll";
import Loader from "../Loader/Loader";
import { getFormattedDuration } from "../../utility/utility";

const ProductProfileDetails = ({ productsData, onClickEdit, onClickActivity, onClickDelete, loading, userData, reviewMode, organizationList, ...props }) => {
    
    return <React.Fragment>
        <SmartScroll
            className="p-2.5 pt-0 flex-grow h-full overflow-hidden"
            data-testid="product-profile-details-scrollbar"
        >
            { loading && <Loader /> }
            <div className="p-4 pt-1.5" data-testid="product-profile-details-container">
                <div className="w-full h-[23px] py-4 pb-6 flex items-center justify-between" data-testid="product-header">
                    <div className="text-lg font-bold text-black-900" data-testid="product-title">
                        {productsData?.product_name || "NA"}
                    </div>
                    {
                        !reviewMode &&
                        <div className="flex items-center gap-3" data-testid="product-actions">
                            {
                                userData?.permissions?.includes("org:product_profiles:manage") &&
                                <ToolTip text="Edit" position="bottom">
                                    <button 
                                        className="w-5 h-5 flex items-center justify-center text-black-500 hover:text-cyan-600" 
                                        onClick={!loading ? onClickEdit : null}
                                        data-testid="edit-button"
                                    >
                                        <IconPencil className="w-[18px] h-[18px]" stroke={1.5} />
                                    </button>
                                </ToolTip>
                            }
                            <ToolTip text="Activity History" position="bottom">
                                <button 
                                    className="w-5 h-5 flex items-center justify-center text-black-500 hover:text-cyan-600" 
                                    onClick={!loading ? onClickActivity : null}
                                    data-testid="activity-button"
                                >
                                    <IconHistory className="w-[18px] h-[18px]" stroke={1.5} />
                                </button>
                            </ToolTip>
                            {
                                userData?.permissions?.includes("org:product_profiles:manage") &&
                                <ToolTip text="Delete" position="bottom">
                                    <button 
                                        className="w-5 h-5 flex items-center justify-center text-black-500 hover:text-cyan-600" 
                                        onClick={!loading ? onClickDelete : null}
                                        data-testid="delete-button"
                                    >
                                        <IconTrash className="w-[18px] h-[18px]" stroke={1.5} />
                                    </button>
                                </ToolTip>
                            }
                        </div>
                    }
                </div>    
                <div className="flex flex-col space-y-3" data-testid="primary-parameters-section">
                    <div className="flex items-center">
                        <span className="text-black-700 font-bold text-sm" data-testid="primary-parameters-title">
                            Primary Parameters
                        </span>
                    </div>
                    <div className="flex flex-col space-y-3">
                        {
                            userData?.role === "org:super_admin" &&
                            <div className="flex items-start space-x-2" data-testid="organization-field">
                                <div className="w-[314px] flex mt-1">
                                    <span className="text-xs text-black-600 font-medium">
                                    Organization
                                    </span>
                                    <span className="text-xs text-red-500 ml-1">*</span>
                                </div>
                                <span className="flex-1 bg-transparent border-none outline-none text-xs text-black-600 ml-2 w-full" data-testid="product-name-value">
                                    {organizationList?.filter(org => org.id === productsData?.org_id)[0]?.name || "NA"}
                                </span>
                            </div>
                        }
                        <div className="flex items-start space-x-2" data-testid="product-name-field">
                            <div className="w-[314px] flex mt-1">
                                <span className="text-xs text-black-600 font-medium">
                                    Product Name
                                </span>
                                <span className="text-xs text-red-500 ml-1">*</span>
                            </div>
                            <span className="flex-1 truncate bg-transparent border-none outline-none text-xs text-black-600 ml-2 w-full" data-testid="product-name-value" title={productsData?.product_name || "NA"}>
                                {productsData?.product_name || "NA"}
                            </span>
                        </div>
                        <div className="flex items-start space-x-2" data-testid="product-description-field">
                            <div className="w-[314px] flex mt-1">
                                <span className="text-xs text-black-600 font-medium">
                                    Description
                                </span>
                            </div>
                            <span className="flex-1 truncate bg-transparent border-none outline-none text-xs text-black-600 ml-2 w-full" data-testid="product-description-value" title={productsData?.product_description || "NA"}>
                                {productsData?.product_description || "NA"}
                            </span>
                        </div>
                        <div className="flex items-start space-x-2" data-testid="product-tags-field">
                            <div className="w-[314px] flex mt-1">
                                <span className="text-xs text-black-600 font-medium">
                                    Tags
                                </span>
                            </div>
                            <div className="h-6 flex items-center space-x-1" data-testid="product-tags-container">
                                <div className="flex space-x-1">
                                    {
                                        productsData?.assigned_tags?.length ? productsData?.assigned_tags?.map((tag, index) => (
                                            <Tag 
                                                key={index}
                                                name={tag.tag_name} 
                                                type="secondary"
                                                icon={<div className={`w-1.5 h-1.5 rounded-full`} style={{ backgroundColor: tag.color_code }}></div>}
                                                className="px-2 py-1"
                                                data-testid={`product-tag-${index}`}
                                            />
                                        )) :  
                                        <div className="text-xs text-black-600 flex gap-2">
                                            <span>NA</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-px bg-black-100 w-full my-5"></div>
                <div className="flex flex-col space-y-3" data-testid="shipping-temperature-section">
                    <div className="flex items-center">
                        <span className="text-black-700 font-bold text-sm" data-testid="shipping-temperature-title">
                            Shipping Temperature Range (Unit: °C)
                        </span>
                        <span className="text-xs text-red-500 ml-1">*</span>
                    </div>
                    <div className="flex gap-4 mt-2">
                        <div className="flex flex-col w-[25%]" data-testid="str-range-name-field">
                            <div className="mb-2">
                                <span className="text-xs font-semibold text-black-600">
                                    Range Name
                                </span>
                                <span className="text-xs text-red-500 ml-1">*</span>
                            </div>
                            <div className="flex items-center w-full" data-testid="str-range-name-value">
                                <span className="text-xs text-black-600">
                                    {productsData?.str_range_name || "NA"}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col w-[25%]" data-testid="str-temperature-field">
                            <div className="mb-2">
                                <span className="text-xs font-semibold text-black-600">
                                    Temperature Range
                                </span>
                                <span className="text-xs text-red-500 ml-1">*</span>
                            </div>
                            <div className="flex items-center" data-testid="str-temperature-value">
                                <span className="bg-transparent border-none outline-none text-xs text-black-600" data-testid="str-temp-min">
                                    {productsData?.str_range_min ?? "NA"}
                                </span>
                                <span className="text-xs mx-2 text-black-600 font-semibold">
                                    to
                                </span>
                                <span className="bg-transparent border-none outline-none text-xs text-black-600" data-testid="str-temp-max">
                                    {productsData?.str_range_max ?? "NA"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-px bg-black-100 w-full my-5"></div>
                {
                    productsData?.ptr_ranges?.length > 0 &&
                    <div className="flex flex-col space-y-3" data-testid="proven-temperature-section">
                        <div className="flex items-center">
                            <span className="text-black-700 font-bold text-sm" data-testid="proven-temperature-title">
                                Proven Temperature Range (Unit: °C)
                            </span>
                        </div>
                        <div className="flex gap-2 mt-2" data-testid="ptr-first-row">
                            <div className="flex flex-col w-[20%]">
                                <div className="mb-2">
                                    <span className="text-xs font-semibold text-black-600">
                                        Range Name
                                    </span>
                                    <span className="text-xs text-red-500 ml-1">*</span>
                                </div>
                            </div>
                            <div className="flex flex-col w-[25%]">
                                <div className="mb-2">
                                    <span className="text-xs font-semibold text-black-600">
                                        Temperature Range
                                    </span>
                                    <span className="text-xs text-red-500 ml-1">*</span>
                                </div>
                            </div>
                            <div className="flex flex-col w-[25%]">
                                <div className="mb-2">
                                    <span className="text-xs font-semibold text-black-600">
                                        Duration
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col w-[25%]">
                                <div className="mb-2">
                                    <span className="text-xs font-semibold text-black-600">
                                        Duration Type
                                    </span>
                                    <span className="text-xs text-red-500 ml-1">*</span>
                                </div>
                            </div>
                            <div className="flex items-end justify-center w-[5%]"></div>
                        </div>
                        {
                            productsData?.ptr_ranges?.map((ptr, index) => {
                                return <React.Fragment key={`${ptr.range_name}_${index}`}>
                                    <div className="flex gap-2 mt-2" data-testid={`ptr-additional-row-${index}`}>
                                        <div className="flex flex-col w-[20%]">
                                            <span className="flex-1 bg-transparent border-none outline-none text-xs text-black-600 w-full" data-testid={`ptr-additional-range-name-${index}`}>
                                                {ptr.range_name || "Enter"}
                                            </span>
                                        </div>
                                        <div className="flex flex-col w-[25%]">
                                            <div className="flex items-center" data-testid={`ptr-additional-temperature-${index}`}>
                                                <span className="bg-transparent border-none outline-none text-xs text-black-600" data-testid={`ptr-additional-temp-min-${index}`}>
                                                    {ptr.range_min ?? "NA"}
                                                </span>
                                                <span className="text-xs mx-2 text-black-600 font-semibold">
                                                    to
                                                </span>
                                                <span className="bg-transparent border-none outline-none text-xs text-black-600" data-testid={`ptr-additional-temp-max-${index}`}>
                                                    {ptr.range_max ?? "NA"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[25%]">
                                            <div className="flex items-center gap-2" data-testid={`ptr-additional-duration-${index}`}>
                                                <span className="bg-transparent border-none outline-none text-xs text-black-600">
                                                    {ptr.duration ? getFormattedDuration(ptr.duration*60*1000) : "NA"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[25%]">
                                            <div className="flex items-center w-full cursor-pointer gap-2" data-testid={`ptr-additional-duration-type-${index}`}>
                                                <IconBracketsContain
                                                    size={14}
                                                    stroke={2}
                                                    className="text-black-500"
                                                />
                                                <span className="bg-transparent border-none outline-none text-xs text-black-600">
                                                    {ptr.duration_type || "NA"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-end justify-center w-[5%]"></div>
                                    </div>
                                    <div className="h-px bg-black-100 w-full my-px"></div>
                                </React.Fragment>
                            }
                        )}
                    </div>
                }
            </div>
        </SmartScroll>
    </React.Fragment>;
};

export default ProductProfileDetails;
