import React from 'react';
import { useUser } from '@clerk/clerk-react';

// DotIcon component for the label
const DotIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
    </svg>
  )
};

const UserTimezone = () => {
  const { user } = useUser();
  const [selectedTimezone, setSelectedTimezone] = React.useState(user?.unsafeMetadata?.timezone || 'UTC');

  const updateTimezone = async (e) => {
    // Prevent default form submission
    e.preventDefault();
    
    // Get form data
    const formData = new FormData(e.target);
    const timezone = formData.get('timezone');
    
    try {
      // Provide visual feedback
      const saveButton = e.target.querySelector('.cl-formButtonPrimary');
      const originalText = saveButton.textContent;
      saveButton.textContent = "Saving....!";
      saveButton.disabled = true;

      // Update user metadata
      await user.update({
        unsafeMetadata: {
          timezone: timezone
        },
      });
            
      // Restore button after 2 seconds
      setTimeout(() => {
        saveButton.textContent = originalText;
        saveButton.disabled = false;
      }, 2000);
      
    } catch (error) {
      console.error("Error updating timezone:", error);
      alert("Failed to update timezone");
    }
  };

  const handleCancel = () => {
    setSelectedTimezone(user?.unsafeMetadata?.timezone || 'UTC');
  };

  return (
    <form className="cl-form cl-internal-ae6s9q" onSubmit={updateTimezone} data-testid="user-timezone-form">
      <div className="cl-header cl-internal-qo3qk7">
        <div className="cl-internal-1pr5xvn">
          <h1 className="cl-headerTitle cl-internal-190cjq9" data-localization-key="userProfile.start.headerTitle__account" data-testid="header-title">Preferred Timezone</h1>
        </div>
      </div>
      <div className="py-4 border-t border-[rgba(0,0,0,0.00.7)]">
        <select 
          name="timezone"
          value={selectedTimezone}
          onChange={(e) => setSelectedTimezone(e.target.value)}
          onFocus={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          data-testid="timezone-select"
        >
          <option value="UTC">UTC+0 (Coordinated Universal Time)</option>
          
          {/* UTC Negative Offsets (Americas) */}
          <optgroup label="UTC- (Americas)">
            <option value="America/New_York">UTC-5 Eastern Time (New York)</option>
            <option value="America/Chicago">UTC-6 Central Time (Chicago)</option>
            <option value="America/Denver">UTC-7 Mountain Time (Denver)</option>
            <option value="America/Los_Angeles">UTC-8 Pacific Time (Los Angeles)</option>
            <option value="America/Anchorage">UTC-9 Alaska Time</option>
            <option value="America/Adak">UTC-10 Hawaii-Aleutian Time</option>
            <option value="Pacific/Honolulu">UTC-10 Hawaii Standard Time</option>
            <option value="America/Phoenix">UTC-7 Mountain Standard Time (Phoenix)</option>
            <option value="America/Toronto">UTC-5 Eastern Time (Toronto)</option>
            <option value="America/Vancouver">UTC-8 Pacific Time (Vancouver)</option>
            <option value="America/Sao_Paulo">UTC-3 Brasilia Time</option>
            <option value="America/Argentina/Buenos_Aires">UTC-3 Argentina Time</option>
            <option value="America/Santiago">UTC-4 Chile Standard Time</option>
          </optgroup>
          
          {/* UTC Positive Offsets (Europe, Africa, Asia, Australia) */}
          <optgroup label="UTC+ (Europe, Africa, Asia, Australia)">
            <option value="Europe/London">UTC+0 GMT/BST (London)</option>
            <option value="Europe/Paris">UTC+1 Central European Time (Paris)</option>
            <option value="Europe/Berlin">UTC+1 Central European Time (Berlin)</option>
            <option value="Europe/Athens">UTC+2 Eastern European Time (Athens)</option>
            <option value="Europe/Moscow">UTC+3 Moscow Time</option>
            <option value="Africa/Cairo">UTC+2 Eastern European Time (Cairo)</option>
            <option value="Africa/Johannesburg">UTC+2 South Africa Standard Time</option>
            <option value="Africa/Lagos">UTC+1 West Africa Time</option>
            <option value="Asia/Dubai">UTC+4 Gulf Standard Time</option>
            <option value="Asia/Kolkata">UTC+5:30 India Standard Time</option>
            <option value="Asia/Singapore">UTC+8 Singapore Time</option>
            <option value="Asia/Tokyo">UTC+9 Japan Standard Time</option>
            <option value="Asia/Shanghai">UTC+8 China Standard Time</option>
            <option value="Asia/Seoul">UTC+9 Korea Standard Time</option>
            <option value="Australia/Sydney">UTC+10 Australian Eastern Time</option>
            <option value="Australia/Perth">UTC+8 Australian Western Time</option>
            <option value="Pacific/Auckland">UTC+12 New Zealand Standard Time</option>
          </optgroup>
        </select>
      </div>

      <div className="u-t-z-btns pt-4 border-t border-[rgba(0,0,0,0.00.7)]">
        <button className="cl-formButtonPrimary cl-button" data-localization-key="userProfile.formButtonPrimary__save" data-variant="solid" data-color="primary" type="submit" data-testid="save-button">Save</button>
        <button className="cl-formButtonReset cl-button" data-localization-key="userProfile.formButtonReset" data-variant="ghost" data-color="primary" type="button" data-testid="cancel-button" onClick={handleCancel}>Cancel</button>
      </div>
    </form>
  );
};

export { DotIcon };
export default UserTimezone; 