import React, { useState, useEffect } from "react";
import Button from "../Button/Button";
import FilterCard from "./FilterCard";

const SelectedFilters = ({selectedFiltersList, clearAll, onFilterChange, setSelectedFilters, ...props}) => {

    return <div className="px-2.5 py-2 pb-1 flex flex-col gap-1.5" data-testid="selected-filters-container">
        <div className="flex flex-wrap items-center gap-1" data-testid="filters-wrapper">
            {
                selectedFiltersList?.map((filter, index) => (
                    <FilterCard
                        key={filter.id}
                        icon={filter.icon}
                        shortName={filter.shortName}
                        category={filter.category}
                        color={filter?.colors}
                        selectedFilterId={filter.selectedFilterId}
                        selectedFilterName={filter.selectedFilterName}
                        filterType={filter.filterType}
                        filteringType={filter.filteringType}
                        changeFilteringType={(label) => setSelectedFilters(selectedFiltersList?.map(f => f.id === filter.id ? {...f, filteringType: label} : f))}
                        onClose={() => setSelectedFilters(selectedFiltersList?.filter(f => f.id !== filter.id))}
                        mainFiltersData={filter.filterInstance}
                        changeFilterInstance={() => {}}
                        data-testid={`filter-card-${index}`}
                        onFilterChange={(updatedFilter) => onFilterChange(updatedFilter, filter.id)}
                        testId={`filter-card-${index}`}
                    />
                ))
            }
            {
                selectedFiltersList?.length > 0 &&
                <Button 
                    text="Clear All" 
                    type="secondary"
                    className="text-xs font-medium px-2 py-1"
                    onClick={clearAll}
                    data-testid="clear-all-button"
                />
            }
        </div>
    </div>;
};

export default SelectedFilters;
