import React, { useState, useEffect } from 'react';
import {
    IconCaretDownFilled,
    IconLayoutGridFilled,
} from '@tabler/icons-react';

const FilterMenuExpand = ({filterTitle, count, color, subFilters: initialSubFilters, icon, searchTerm, onFilterChange, parentId, parentChecked, ...props}) => {

    const [expandDropdown, setExpandDropdown] = useState(false);
    const [filtersData, setFiltersData] = useState(() => ({
        items: initialSubFilters?.map(filter => ({
            ...filter,
            checked: filter.checked || false
        })) || [],
        parentChecked: initialSubFilters?.length 
            ? initialSubFilters?.every(filter => filter.checked) 
            : parentChecked || false
    }));

    const handleParentCheckbox = (e) => {
        const isChecked = e.target.checked;
        const updatedItems = filtersData.items.map(item => ({
            ...item,
            checked: isChecked
        }));
        
        setFiltersData(prev => ({
            items: updatedItems,
            parentChecked: isChecked
        }));

        if(updatedItems?.length) {
            // Pass complete filter object to parent
            onFilterChange && onFilterChange({
                filterTitle,
                count,
                color,
                subFilters: updatedItems
            });
        } else {
            // Pass complete filter object to parent
            onFilterChange && onFilterChange({
                filterTitle,
                count,
                id: parentId,
                checked: isChecked
            });
        }
    };

    useEffect(() => {
        if(searchTerm.length > 0) {
            setExpandDropdown(true);
        } else {
            setExpandDropdown(false);
        }
    }, [searchTerm]);

    const handleSubFilterCheckbox = (index, isChecked) => {
        setFiltersData(prev => {
            const newItems = [...prev.items];
            newItems[index] = {
                ...newItems[index],
                checked: isChecked
            };
            
            const allChecked = newItems.every(item => item.checked === true);
            
            // Pass complete filter object to parent
            onFilterChange && onFilterChange({
                filterTitle,
                count,
                color,
                subFilters: newItems
            });
            
            return {
                items: newItems,
                parentChecked: allChecked
            };
        });
    };

    return <React.Fragment>
    <div className="flex items-center justify-between p-2 cursor-pointer hover:bg-black-50" 
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if(filtersData.items?.length) {
                setExpandDropdown(!expandDropdown)
            }
        }}
        data-testid="filter-menu-header"
    >
        <div className="flex items-center gap-2" data-testid="filter-header-content">
            {
                filtersData.items?.length ?
                <IconCaretDownFilled 
                    size={16} 
                    stroke={1.5} 
                    className={`text-black-400 ${expandDropdown ? '' : '-rotate-90'}`} 
                    data-testid="dropdown-icon"
                /> : null
            }
            <div className="w-[14px] h-[14px] flex items-center justify-center" data-testid="parent-checkbox-container">
                <input 
                    type="checkbox" 
                    className="w-3 h-3 accent-black-900 cursor-pointer"
                    checked={filtersData.parentChecked}
                    onChange={handleParentCheckbox}
                    onClick={(e) => e.stopPropagation()}
                    data-testid="parent-checkbox"
                />
            </div>
            <div className="flex items-center gap-2">
                {
                    filtersData.items?.length ?
                    <IconLayoutGridFilled data-testid="filter-icon" className={`w-3 h-3 text-${color} rotate-45`} color={color} />
                    :
                    icon
                }
                <span className="text-sm text-black-400 font-bold" data-testid="filter-title">{filterTitle}</span>
            </div>
        </div>
        <span className="text-xs text-black-400" data-testid="filter-count">{count} products</span>
    </div>
    {
        expandDropdown &&
        <React.Fragment>
            <div data-testid="sub-filters-container">
            {
                filtersData.items?.filter(subFilter => 
                    subFilter.filterTitle.toLowerCase().includes(searchTerm.toLowerCase()) || 
                    filtersData.items.length === 1
                )?.map((subFilter, index) => (
                    <div 
                        key={index} 
                        className="flex items-center justify-between p-2 cursor-pointer hover:bg-black-50 pl-14"
                        data-testid={`sub-filter-${index}`}
                    >
                        <div className="flex items-center gap-2">
                            <div className="w-[14px] h-[14px] flex items-center justify-center">
                                <input 
                                    type="checkbox" 
                                    className="w-3 h-3 accent-black-900 cursor-pointer"
                                    checked={subFilter.checked}
                                    onChange={(e) => handleSubFilterCheckbox(index, e.target.checked)}
                                    data-testid={`sub-filter-checkbox-${index}`}
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className={`w-2 h-2 bg-${color} rounded-full`} data-testid={`sub-filter-dot-${index}`} style={{backgroundColor: color}} />
                                <span className="text-sm text-black-400 font-bold" data-testid={`sub-filter-title-${index}`}>{subFilter.filterTitle}</span>
                            </div>
                        </div>
                        <span className="text-xs text-black-400" data-testid={`sub-filter-count-${index}`}>{subFilter.count} products</span>
                    </div>
                ))
            }
            </div>
        </React.Fragment>
    }
    </React.Fragment>
};

export default FilterMenuExpand;
