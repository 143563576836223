import { SignIn } from '@clerk/clerk-react';

const SignedInPage = () => {
  return (
    <div className="flex justify-center items-center min-h-[70vh]">
      <SignIn 
        forceRedirectUrl={import.meta.env.VITE_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL}
        appearance={{
          elements: {
            formButtonPrimary: 'bg-cyan-600 hover:bg-cyan-700 border-0 text-white',
          },
        }}
      />
    </div>
  )
}

export default SignedInPage;