import React, { useState, useRef, useEffect } from "react";
import {
IconPlus,
IconTag,
IconCircleXFilled,
IconCircleCheckFilled,
} from '@tabler/icons-react';
import Input from "../Input/Input";
import Button from "../Button/Button";
import SelectBox from "../Selectbox/Selectbox";
import SingleSelectFilter from "../Filter/SingleSelectFilter";
import SmartScroll from "../SmartScroll/SmartScroll";
import Tag from "../Tag/Tag";
import { useSession, useUser } from '@clerk/clerk-react';
import { createClerkSupabaseClientProduct, createClerkSupabaseClientLandingZone } from "../../utility/utility";
import Loader from "../Loader/Loader";
import { getExcludeRangeLabel } from "../../utility/utility";
import ProductProfileDetails from "./ProductProfileDetails";
import { includes } from "lodash";

const ProductProfileModify = ({ editView, strRanges, productsData, onProductCancel, onProductSave, setAlertMessage, organizationList, userData, setProductDetailsCopy, ...props }) => {
    
    const containerRef = useRef(null);
    const tagFilterRef = useRef(null);

    const { session } = useSession();

    const [productDetails, setProductDetails] = useState({
        product_name: "",
        product_description: "",
        str_id: 12,
        str_range_name: "Custom",
        str_range_min: null,
        str_range_max: null,
        org_id: null,
        ptr_ranges: [],
        assigned_tags: []
    });
    const [productDuration, setProductDuration] = useState([{
        days: null,
        hours: null,
        mins: null
    }]);
    const [showTagFilter, setShowTagFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tagsList, setTagsList] = useState([]);
    const [tagLoading, setTagLoading] = useState(false);
    const [tagsFilterData, setTagsFilterData] = useState({
        "Tags": {
            icon: <IconTag size={16} className="w-4 h-4 text-black-500" stroke={2} />,
            data: []
        }
    });
    const [selectedTags, setSelectedTags] = useState([]);
    const [editViewSelectedTags, setEditViewSelectedTags] = useState([]);
    const [isSaveMode, setIsSaveMode] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tagFilterRef.current && !tagFilterRef.current.contains(event.target)) {
                setShowTagFilter(false);
            }
        };
        
        if (showTagFilter) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTagFilter]);

    // Get tags that were in editViewSelectedTags but removed from selectedTags
    const getMissingTags = () => {
        return editViewSelectedTags?.filter(editTag => 
            !selectedTags.some(tag => tag.tag_id === editTag.tag_id)
        );
    };

    // Get tags that are newly added to selectedTags and not in editViewSelectedTags
    const getNewlyAddedTags = () => {
        return selectedTags?.filter(tag => 
            !editViewSelectedTags.some(editTag => editTag.tag_id === tag.tag_id)
        );
    };

    useEffect(() => {
        fetchTagsList();
        if(editView && productsData?.ptr_ranges){
            setProductDetails({
                ...productsData,
                ptr_ranges: productsData.ptr_ranges.map(range => {
                    return {
                        range_name: getExcludeRangeLabel(range.range_min, range.range_max),
                        range_min: range.range_min,
                        range_max: range.range_max,
                        duration: range.duration,
                        duration_type: range.duration_type
                    };
                })
            });
            
            // Initialize productDuration based on the duration values in productsData
            if (productsData.ptr_ranges.length > 0) {
                const initialDurations = productsData.ptr_ranges.map(range => {
                    const totalMinutes = range.duration || 0;
                    const days = Math.floor(totalMinutes / (24 * 60));
                    const remainingMinutes = totalMinutes % (24 * 60);
                    const hours = Math.floor(remainingMinutes / 60);
                    const mins = remainingMinutes % 60;
                    
                    return { days, hours, mins };
                });
                
                setProductDuration(initialDurations);
            }

            if(productsData.assigned_tags?.length > 0){
                setSelectedTags(productsData.assigned_tags);

                const initialTags = productsData.assigned_tags.map(tag => {
                    return {
                        tag_id: tag.tag_id,
                        tag_name: tag.tag_name,
                        entity_id: productsData.id, 
                        entity_type: "product_profile",
                        color_code: tag.color_code
                    }
                });

                setSelectedTags(initialTags);
                setEditViewSelectedTags(initialTags);
            }
        }
    }, [editView, productsData]);

    useEffect(() => {
        if(productDetails.ptr_ranges.length > 0){
            const hasChanged = productDetails.ptr_ranges.some(range => {
                const currentName = getExcludeRangeLabel(range.range_min, range.range_max);
                return range.range_name !== currentName;
            });
            
            if (hasChanged) {
                setProductDetails(prev => ({
                    ...prev,
                    ptr_ranges: prev.ptr_ranges.map(range => ({ 
                        ...range, 
                        range_name: getExcludeRangeLabel(range.range_min, range.range_max) 
                    }))
                }));
            }
        }
    }, [JSON.stringify(productDetails.ptr_ranges.map(range => ({min: range.range_min, max: range.range_max})))]);

    useEffect(() => {
        if (tagsList?.length > 0) {
            const tagsData = formatFilteredTagsData(
                tagsList, 
                selectedTags,
                editView, 
                productDetails.id
            );
            
            setTagsFilterData(tagsData);
        }
    }, [selectedTags, tagsList]);

    const convertToMinutes = (days, hours, minutes) => {
        return (days * 24 * 60) + (hours * 60) + minutes;
    };

    const updatePtrRange = (index, field, value) => {
        setProductDetails(prev => ({
            ...prev,
            ptr_ranges: [
                ...prev.ptr_ranges.slice(0, index),
                {
                    ...prev.ptr_ranges[index],
                    [field]: value
                },
                ...prev.ptr_ranges.slice(index + 1)
            ]
        }));
    };

    const updateDuration = (index, type, value) => {
        const numValue = value === "" ? null : parseInt(value) || 0;
        setProductDuration(prev => {
            const newDuration = [...prev];
            if (!newDuration[index]) {
                newDuration[index] = { days: null, hours: null, mins: null };
            }
            newDuration[index] = {
                ...newDuration[index],
                [type]: numValue
            };
            
            // Convert to total minutes and update ptr_ranges
            const { days, hours, mins } = newDuration[index];
            const totalMinutes = convertToMinutes(days || 0, hours || 0, mins || 0);
            updatePtrRange(index, 'duration', totalMinutes);
            
            return newDuration;
        });
    };

    const createProductProfile = async () => {
        if (!session) return;

        const client = createClerkSupabaseClientProduct(session);
        setLoading(true);
        try {
            const { data, error } = await client.from('product_profiles')
            .insert([
                { 
                    product_name: productDetails.product_name,
                    product_description: productDetails.product_description,
                    str_id: productDetails.str_id,
                    str_range_name: productDetails.str_range_name,
                    str_range_min: productDetails.str_range_min,
                    str_range_max: productDetails.str_range_max,
                    ptr_ranges: productDetails.ptr_ranges,
                    ...(userData?.role === "org:super_admin" && { org_id: productDetails.org_id })
                },
            ])
            .select()

            if (error) {
                console.error('Error creating product profile via RPC:', error);
                setAlertMessage({
                    show: true,
                    message: error.message,
                    type: "warning"
                });
                return;
            }
            
            if(data && data.length > 0){
                const productProfileId = data[0].id;

                if(selectedTags.length > 0) {
                    await assignTagsToProductProfile(selectedTags, productProfileId);
                }

                // If everything is successful, call the onProductSave callback
                onProductSave();
                setProductDetailsCopy(productDetails);
            }
            
        } catch (error) {
            console.error('Error in createProductProfile:', error);
        } finally {
            setLoading(false);
        }
    }

    const updateProductProfile = async () => {
        if (!session) return;
    
        const client = createClerkSupabaseClientProduct(session);
        setLoading(true);

        const removedTags = getMissingTags();
        const newlyAddedTags = getNewlyAddedTags();

        if(newlyAddedTags.length > 0) {
            await assignTagsToProductProfile(newlyAddedTags);
        }
        if(removedTags.length > 0) {
            await removeTagsFromProductProfile(removedTags);
        }
        try {
                const { data, error } = await client.from('product_profiles')
                .update([
                { 
                    // org_id: 'dummy_org_id', 
                    product_name: productDetails.product_name,
                    product_description: productDetails.product_description,
                    str_id: productDetails.str_id,
                    str_range_name: productDetails.str_range_name,
                    str_range_min: productDetails.str_range_min,
                    str_range_max: productDetails.str_range_max,
                    ptr_ranges: productDetails.ptr_ranges
                },
            ])
            .eq('id', productDetails.id)
            .select()
    
            if (error) {
                console.error('Error updating product profile:', error);
                setAlertMessage({
                    show: true,
                    message: error.message,
                    type: "warning"
                });
                return;
            }
            
            // If everything is successful, call the onProductSave callback
            onProductSave();
            setProductDetailsCopy(productDetails);
            
        } catch (error) {
            console.error('Error in updateProductProfile:', error);
        } finally {
            setLoading(false);
        }
    }

    const removeTagsFromProductProfile = async (tagIds) => {
        if (!session) return;
    
        const client = createClerkSupabaseClientLandingZone(session);
    
        try {
            const { data, error } = await client.from('assigned_tags')
                .delete()
                .in('tag_id', tagIds.map(tag => tag.tag_id))
                .select();
    
            if (error) {
                console.error('Error in deleteTagsFromProductProfile:', error);
                return;
            }
    
        } catch (error) {
            console.error('Error in deleteTagsFromProductProfile:', error);
        } finally {
            setLoading(false);
        }
    }

    const assignTagsToProductProfile = async (tags, productId) => {
        if (!session) return;
    
        const client = createClerkSupabaseClientLandingZone(session);
    
        try {
            const { data, error } = await client.from('assigned_tags')
                .insert(tags.map(tag => {
                    return { 
                        tag_id: tag.tag_id,
                        entity_id: productId ? productId : productDetails.id, 
                        entity_type: "product_profile"
                    }
                }))
                .select();
    
            if (error) {
                console.error('Error in assignTagsToProductProfile:', error);
                return;
            }
    
        } catch (error) {
            console.error('Error in assignTagsToProductProfile:', error);
        } finally {
            setLoading(false);
        }
    }

    const formatFilteredTagsData = (tagsList, selectedTags) => {
        // Group tags by group_name
        const groupedTags = {};
        const ungroupedTags = [];
        
        // Filter out already assigned tags
        const filteredTags = tagsList?.filter(tag => {
            // Skip tags that are already selected for this product
            if (selectedTags.some(selectedTag => selectedTag.tag_id === tag.id)) {
                return false;
            }
            return true;
        });
        
        filteredTags?.forEach(tag => {
            if (tag?.group_name) {
                if (!groupedTags[tag.group_name]) {
                    groupedTags[tag.group_name] = [];
                }
                groupedTags[tag.group_name].push(tag);
            } else {
                ungroupedTags.push(tag);
            }
        });
        
        // Transform into the required format
        const tagsData = {
            "Tags": {
                icon: <IconTag size={16} className="w-4 h-4 text-black-500" stroke={2} />,
                data: []
            }
        };
        
        // Add grouped tags
        Object.keys(groupedTags)?.forEach(groupName => {
            const group = groupedTags[groupName];
            
            tagsData.Tags.data.push({
                filterTitle: groupName,
                count: group?.length || 0,
                color: group[0]?.color_code || "#000000",
                subFilters: group?.map(tag => ({
                    filterTitle: tag?.tag_name || "",
                    count: 1,
                    id: tag?.id,
                    color: tag?.color_code
                }))
            });
        });
        
        // Add ungrouped tags if any exist
        if (ungroupedTags?.length > 0) {
            tagsData.Tags.data.push({
                filterTitle: "Tags (Ungrouped)",
                count: ungroupedTags?.length || 0,
                color: ungroupedTags[0]?.color_code || "#000000", // Default color for ungrouped
                subFilters: ungroupedTags?.map(tag => ({
                    filterTitle: tag?.tag_name || "",
                    count: 1,
                    id: tag?.id,
                    color: tag?.color_code
                }))
            });
        }
        
        return tagsData;
    };

    const fetchTagsList = async (searchQuery = '') => {
        if (!session) return;
    
        const client = createClerkSupabaseClientLandingZone(session);
        setTagLoading(true);
    
        try {
            let query = client.from('tags').select();
            if (searchQuery) {
                query = query.or(`tag_name.ilike.%${searchQuery}%`);
            }
    
            const { data, error } = await query;
            if (error) {
                console.error('Error fetching tags:', error);
                return;
            } else {
                setTagsList(data);
            }
        } catch (error) {
            console.error('Error in fetchTagsList:', error);
        } finally {
            setTagLoading(false);
        }
    };

    const onTagClick = (filterTitle, id, color) => {
        
        const tagExists = selectedTags.some(tag => tag.tag_id === id);
        if (!tagExists) {
            const newTag = { 
                tag_id: id,
                tag_name: filterTitle,
                entity_id: productDetails.id, 
                entity_type: "product_profile",
                color_code: color
            };
            setSelectedTags([...selectedTags, newTag]);
            setProductDetails({
                ...productDetails,
                assigned_tags: [...selectedTags, newTag]
            })

        }
        setShowTagFilter(false);
    }

    return <div ref={containerRef} className="flex flex-col h-full relative" data-testid="product-profile-modify-container">
        { (loading || tagLoading) && <Loader abs={true} /> }
        {
            isSaveMode ?
            <ProductProfileDetails
                userData={userData}
                loading={loading}
                productsData={productDetails}
                organizationList={organizationList}
                reviewMode={true}
                data-testid="product-profile-details"
            />
            : 
            <SmartScroll 
                containerRef={containerRef}
                peerElementClassName=".top-section"
                className="p-2.5 pt-0 flex-grow h-full overflow-hidden"
                data-testid="profile-modify-scrollbar"
            >
                <div className="p-4 pt-1.5" data-testid="profile-modify-content">
                    <div className="flex flex-col space-y-3" data-testid="primary-parameters-section">
                        <div className="flex items-center">
                            <span className="text-black-700 font-bold text-sm" data-testid="primary-parameters-title">
                                Primary Parameters
                            </span>
                        </div>
                        <div className="flex flex-col space-y-3">
                            {
                                userData?.role === "org:super_admin" &&
                                <div className="flex items-start space-x-2" data-testid="organization-field">
                                    <div className="w-[314px] flex mt-1">
                                        <span className="text-xs text-black-600 font-medium">
                                            Organization
                                        </span>
                                        <span className="text-xs text-red-500 ml-1">*</span>
                                    </div>
                                    <SelectBox
                                        className={"w-[30%]"}
                                        placeholder="Select"
                                        options={organizationList.map(org => ({
                                            label: org.name,
                                            value: org.id
                                        }))}
                                        fontClassName={"text-xs text-black-600"}
                                        value={productDetails.org_id}
                                        onChange={(option) => {
                                            let orgId = option.value;
                                            setProductDetails({ 
                                                ...productDetails, 
                                                org_id: orgId
                                            });
                                        }}
                                        data-testid="organization-name-select"
                                        disabled={editView}
                                    />
                                </div>
                            }
                            <div className="flex items-start space-x-2" data-testid="product-name-field">
                                <div className="w-[314px] flex mt-1">
                                    <span className="text-xs text-black-600 font-medium">
                                        Product Name
                                    </span>
                                    <span className="text-xs text-red-500 ml-1">*</span>
                                </div>
                                <Input 
                                    icon={false}
                                    placeholder="Enter"
                                    className="py-1 px-3"
                                    inputClassName="w-full text-xs"
                                    value={productDetails?.product_name}
                                    maxLength={300}
                                    onChange={(name) => {
                                        setProductDetails({ 
                                            ...productDetails, 
                                            product_name: name 
                                        });
                                    }}
                                    data-testid="product-name-input"
                                />
                            </div>
                            <div className="flex items-start space-x-2" data-testid="product-description-field">
                                <div className="w-[314px] flex mt-1">
                                    <span className="text-xs text-black-600 font-medium">
                                        Description
                                    </span>
                                </div>
                                <Input 
                                    icon={false}
                                    placeholder="Enter"
                                    className="py-1 px-3"
                                    inputClassName="w-full text-xs"
                                    value={productDetails?.product_description}
                                    maxLength={500}
                                    onChange={(description) => {
                                        setProductDetails({ 
                                            ...productDetails, 
                                            product_description: description 
                                        });
                                    }}
                                    data-testid="product-description-input"
                                />
                            </div>
                            <div className="flex items-start space-x-2 relative" data-testid="product-tags-field">
                                <div className="w-[314px] flex mt-1">
                                    <span className="text-xs text-black-600 font-medium">
                                        Tags
                                    </span>
                                </div>
                                <div className="flex relative" ref={tagFilterRef} data-testid="tag-filter-container">
                                    {
                                        selectedTags.length === 0 &&
                                        <React.Fragment>
                                            <Button 
                                                icon={<IconTag className="w-4 h-4 text-black-300" stroke={1.5} />} 
                                                text="Add tag(s)" 
                                                type="secondary-v2"
                                                className="py-1 px-2 text-xs"
                                                onClick={(e) => {
                                                    if(tagLoading) return;
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setShowTagFilter(!showTagFilter);
                                                }}
                                                data-testid="add-tags-button"
                                            />
                                            {
                                                showTagFilter ?
                                                <SingleSelectFilter 
                                                    filters={tagsFilterData} 
                                                    filterType={"Tags"} 
                                                    data-testid="tag-filter" 
                                                    onClick={onTagClick}
                                                    className="absolute top-7 left-0 z-50"
                                                />
                                                : null
                                            }
                                        </React.Fragment>
                                    }
                                    {
                                        selectedTags.length > 0 &&
                                        <div className="h-6 flex items-center space-x-1" data-testid="demo-tags-container">
                                            <div className="flex space-x-1">
                                                {
                                                    selectedTags.map((tag, index) => {
                                                        return <Tag 
                                                            key={`tag-${index}`}
                                                            name={tag.tag_name}
                                                            type="secondary"
                                                            icon={<div className={`w-1.5 h-1.5 bg-red-500 rounded-full`} style={{backgroundColor: tag.color_code}}></div>}
                                                            className="px-2 py-1"
                                                            close={true}
                                                            onClose={() => {
                                                                const updatedTags = selectedTags.filter((_, i) => i !== index);
                                                                setSelectedTags(updatedTags);
                                                                setProductDetails({
                                                                    ...productDetails,
                                                                    assigned_tags: updatedTags
                                                                })
                                                            }}
                                                            hoverable={true}
                                                            data-testid={`tag-${index}`}
                                                        />
                                                    })
                                                }
                                            </div>
                                            <div className="flex relative">
                                                <Button
                                                    title={tagLoading ? "Loading..." : !tagsList?.length ? "No Tags Available" : ""}
                                                    type="primary"
                                                    icon={<IconPlus size={16} stroke={2} className="text-white" />}
                                                    className="w-[40px] text-xs font-medium py-1"
                                                    onClick={(e) => {
                                                        if(tagLoading) return;
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setShowTagFilter(!showTagFilter);
                                                    }}
                                                    data-testid="add-more-tags-button"
                                                />
                                                {
                                                    showTagFilter && tagsList?.length ?
                                                    <SingleSelectFilter 
                                                        filters={tagsFilterData} 
                                                        className="absolute top-7 left-[0px]" 
                                                        filterType={"Tags"} 
                                                        data-testid="tag-filter-dropdown" 
                                                        onClick={onTagClick}
                                                    />
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-px bg-black-100 w-full my-5"></div>
                    <div className="flex flex-col space-y-3" data-testid="shipping-temperature-section">
                        <div className="flex items-center">
                            <span className="text-black-700 font-bold text-sm" data-testid="shipping-temperature-title">
                                Shipping Temperature Range (Unit: °C)
                            </span>
                            <span className="text-xs text-red-500 ml-1">*</span>
                        </div>
                        <div className="flex gap-4 mt-2">
                            <div className="flex flex-col w-[25%]" data-testid="str-range-name-field">
                                <div className="mb-1">
                                    <span className="text-xs font-semibold text-black-600">
                                        Range Name
                                    </span>
                                    <span className="text-xs text-red-500 ml-1">*</span>
                                </div>
                                <SelectBox
                                    placeholder="Select"
                                    options={strRanges}
                                    fontClassName={"text-xs text-black-600"}
                                    value={productDetails.str_id}
                                    onChange={(option) => {
                                        let rangeName = option.label;
                                        if (rangeName.includes('[')) {
                                            rangeName = rangeName.replace(/\s*\[.*?\]\s*$/, '').trim();
                                        }
                                        setProductDetails({ 
                                            ...productDetails, 
                                            str_id: option.value,
                                            str_range_name: rangeName,
                                            str_range_min: option.range_min === null ? null : Number(option.range_min),
                                            str_range_max: option.range_max === null ? null : Number(option.range_max)
                                        });
                                    }}
                                    data-testid="str-range-name-select"
                                />
                            </div>
                            <div className="flex flex-col w-[25%]" data-testid="str-temperature-field">
                                <div className="mb-1">
                                    <span className="text-xs font-semibold text-black-600">
                                        Temperature Range
                                    </span>
                                    <span className="text-xs text-red-500 ml-1">*</span>
                                </div>
                                <div className="flex items-center">
                                    <Input 
                                        icon={false}
                                        placeholder="Enter"
                                        className="py-1 px-3"
                                        inputClassName="w-full text-xs"
                                        value={productDetails.str_range_min}
                                        type="number"
                                        min={-200}
                                        max={200}
                                        onKeyDown={(e) => (e.key === '-' && productDetails.str_range_min?.toString().includes('-') || e.key === 'e' || e.key === 'E') && e.preventDefault()}
                                        disabled={productDetails?.str_range_name?.toLowerCase() !== "custom" && productDetails?.str_range_name !== ""}
                                        onChange={(tempMin) => {
                                            if(tempMin.includes(".") && tempMin.split(".")[1].length > 2) return;
                                            setProductDetails({ 
                                                ...productDetails, 
                                                str_range_min: tempMin === "" ? null : Number(tempMin)
                                            });
                                        }}
                                        data-testid="str-temp-min-input"
                                    />
                                    <span className="text-xs mx-2 text-black-600 font-semibold">
                                        to
                                    </span>
                                    <Input 
                                        icon={false}
                                        placeholder="Enter"
                                        className="py-1 px-3"
                                        inputClassName="w-full text-xs"
                                        value={productDetails.str_range_max}
                                        type="number"
                                        min={-200}
                                        max={200}
                                        disabled={productDetails?.str_range_name?.toLowerCase() !== "custom" && productDetails?.str_range_name !== ""}
                                        onChange={(tempMax) => {
                                            if(tempMax.includes(".") && tempMax.split(".")[1].length > 2) return;
                                            setProductDetails({ 
                                                ...productDetails, 
                                                str_range_max: tempMax === "" ? null : Number(tempMax) 
                                            });
                                        }}
                                        data-testid="str-temp-max-input"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    { false && <span className="text-xs text-red-500">Error Message</span> }
                    <div className="h-px bg-black-100 w-full my-5"></div>
                    <div className="flex flex-col space-y-3" data-testid="proven-temperature-section">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <span className="text-black-700 font-bold text-sm" data-testid="proven-temperature-title">
                                    Proven Temperature Range (Unit: °C)
                                </span>
                            </div>
                            <Button 
                                icon={<IconPlus className={`w-3 h-3 ${productDetails.ptr_ranges.length === 20 || productDetails.ptr_ranges.length > 20 ? "text-black-300" : "text-cyan-600"} mr-1`} stroke={2.5} />} 
                                text="Add" 
                                type="primary-v3"
                                className="py-1 px-2 text-xs font-medium"
                                disabled={productDetails.ptr_ranges.length === 20 || productDetails.ptr_ranges.length > 20}
                                onClick={() => {
                                    if(productDetails.ptr_ranges.length < 20) {
                                        let newPtr = {
                                            range_name: "",
                                            range_min: null,
                                            range_max: null,
                                            duration: null,
                                            duration_type: ""
                                        }
                                        setProductDetails({
                                            ...productDetails,
                                            ptr_ranges: [
                                                ...productDetails.ptr_ranges,
                                                newPtr
                                            ]
                                        });
                                    }
                                }}
                                data-testid="add-temperature-range-button"
                            />
                        </div>
                        {
                            productDetails?.ptr_ranges?.length > 0 ?
                            <React.Fragment>
                                <div className="flex flex-col">
                                    <div className="flex gap-4 mt-2" data-testid="ptr-first-row">
                                        <div className="flex flex-col w-[20%]">
                                            <div className="mb-1">
                                                <span className="text-xs font-semibold text-black-600">
                                                    Range Name
                                                </span>
                                                <span className="text-xs text-red-500 ml-1">*</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[25%]">
                                            <div className="mb-1">
                                                <span className="text-xs font-semibold text-black-600">
                                                    Temperature Range
                                                </span>
                                                <span className="text-xs text-red-500 ml-1">*</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[25%]">
                                            <div className="mb-1">
                                                <span className="text-xs font-semibold text-black-600">
                                                    Duration
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[25%]">
                                            <div className="mb-1">
                                                <span className="text-xs font-semibold text-black-600">
                                                    Duration Type
                                                </span>
                                                <span className="text-xs text-red-500 ml-1">*</span>
                                            </div>
                                        </div>
                                        <div className="flex items-end justify-center w-[5%]"></div>
                                    </div>
                                </div>
                                {
                                    productDetails?.ptr_ranges?.map((ptr, index) => {
                                        return (
                                            <div className="text-xs text-red-500 mt-1" key={index} style={(index === productDetails.ptr_ranges.length - 1) ? {marginBottom: '40px'} : {}}>
                                                <div className="h-px bg-black-100 w-full my-px"></div>
                                                <div className="flex gap-4 mt-2" data-testid={`ptr-additional-row-${index}`}>
                                                    <div className="flex flex-col w-[20%]">
                                                        <Input 
                                                            icon={false}
                                                            placeholder="Range Name"
                                                            className="py-1 px-3"
                                                            inputClassName="w-full text-xs"
                                                            // value={productDetails.ptr_ranges[index]?.range_name}
                                                            value={getExcludeRangeLabel(productDetails.ptr_ranges[index]?.range_min, productDetails.ptr_ranges[index]?.range_max)}
                                                            disabled={true}
                                                            onChange={(rangeName) => {
                                                                updatePtrRange(index, 'range_name', rangeName);
                                                            }}
                                                            data-testid={`ptr-additional-range-name-${index}`}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col w-[25%]">
                                                        <div className="flex items-center">
                                                            <Input 
                                                                icon={false}
                                                                placeholder="Enter"
                                                                className="py-1 px-3"
                                                                inputClassName="w-full text-xs"
                                                                value={productDetails.ptr_ranges[index]?.range_min}
                                                                type="number"
                                                                min={-200}
                                                                max={200}
                                                                onChange={(tempMin) => {
                                                                    if(tempMin.includes(".") && tempMin.split(".")[1].length > 2) return;
                                                                    updatePtrRange(index, 'range_min', tempMin === "" ? null : Number(tempMin));
                                                                }}
                                                                data-testid={`ptr-additional-temp-min-${index}`}
                                                            />
                                                            <span className="text-xs mx-2 text-black-600 font-semibold">
                                                                to
                                                            </span>
                                                            <Input 
                                                                icon={false}
                                                                placeholder="Enter"
                                                                className="py-1 px-3"
                                                                inputClassName="w-full text-xs"
                                                                value={productDetails.ptr_ranges[index]?.range_max}
                                                                type="number"
                                                                min={-200}
                                                                max={200}
                                                                onChange={(tempMax) => {
                                                                    if(tempMax.includes(".") && tempMax.split(".")[1].length > 2) return;
                                                                    updatePtrRange(index, 'range_max', tempMax === "" ? null : Number(tempMax));
                                                                }}
                                                                data-testid={`ptr-additional-temp-max-${index}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col w-[25%]">
                                                        <div className="flex items-center gap-2">
                                                            <Input 
                                                                icon={false}
                                                                placeholder="Days"
                                                                className="py-1 px-3"
                                                                inputClassName="w-full text-xs"
                                                                type="number"
                                                                min={0}
                                                                value={productDuration[index]?.days}
                                                                onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
                                                                onChange={(value) => updateDuration(index, 'days', value)}
                                                                data-testid={`ptr-additional-duration-days-${index}`}
                                                            />
                                                            <Input 
                                                                icon={false}
                                                                placeholder="Hours"
                                                                className="py-1 px-3"
                                                                inputClassName="w-full text-xs"
                                                                value={productDuration[index]?.hours}
                                                                type="number"
                                                                min={0}
                                                                onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
                                                                onChange={(value) => updateDuration(index, 'hours', value)}
                                                                data-testid={`ptr-additional-duration-hours-${index}`}
                                                            />
                                                            <Input 
                                                                icon={false}
                                                                placeholder="Mins"
                                                                className="py-1 px-3"
                                                                inputClassName="w-full text-xs"
                                                                type="number"
                                                                min={0}
                                                                value={productDuration[index]?.mins}
                                                                onKeyDown={(e) => (e.key === '-' || e.key === 'e' || e.key === 'E') && e.preventDefault()}
                                                                onChange={(value) => updateDuration(index, 'mins', value)}
                                                                data-testid={`ptr-additional-duration-minutes-${index}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col w-[25%]">
                                                        <SelectBox
                                                            placeholder="Select an option"
                                                            options={[
                                                                { value: 'Immediate', label: 'Immediate' },
                                                                { value: 'Cumulative', label: 'Cumulative' }
                                                            ]}
                                                            fontClassName="text-xs text-black-600"
                                                            value={productDetails.ptr_ranges[index]?.duration_type}
                                                            onChange={(option) => {
                                                                updatePtrRange(index, 'duration_type', option.value);
                                                            }}
                                                            data-testid={`ptr-additional-duration-type-${index}`}
                                                        />
                                                    </div>
                                                    <div className="flex items-end justify-center w-[5%]">
                                                        <button 
                                                            className="flex items-center h-6" 
                                                            onClick={() => {
                                                                setProductDetails(prev => ({
                                                                    ...prev,
                                                                    ptr_ranges: prev.ptr_ranges.filter((_, i) => i !== index)
                                                                }));
                                                                setProductDuration(prev => 
                                                                    prev.filter((_, i) => i !== index)
                                                                );
                                                            }}
                                                            data-testid={`ptr-remove-row-${index}`}
                                                        >
                                                            <IconCircleXFilled size={16} stroke={1.5} className="text-black-500 hover:text-black-800"/>
                                                        </button> 
                                                    </div>
                                                </div>
                                                { false && <span className="text-xs text-red-500 mt-1">Error Message</span> }
                                            </div>
                                        )
                                    })
                                }
                            </React.Fragment>
                            : null
                        }
                    </div>
                </div>
            </SmartScroll>
        }
        <div className="top-section mt-auto border-t border-black-100 py-2 px-4" data-testid="action-buttons-section">
            {
                isSaveMode ?
                <div className="w-full flex justify-end gap-2">
                    <Button 
                        text="Edit" 
                        type="primary-v2"
                        className="w-[115px] text-xs font-medium py-2"
                        onClick={() => setIsSaveMode(false)}
                        data-testid="edit-button"
                    />
                    <Button 
                        text="Save" 
                        type="tertiary"
                        className="w-[115px] text-xs font-medium py-2"
                        onClick={editView ? updateProductProfile : createProductProfile}
                        data-testid="save-button"
                    />
                </div>
                : <div className="w-full flex justify-end gap-2">
                    <Button 
                        text="Cancel" 
                        type="primary-v2"
                        className="w-[115px] text-xs font-medium py-2"
                        onClick={onProductCancel}
                        data-testid="cancel-button"
                    />
                    <Button 
                        text="Review" 
                        type="tertiary"
                        className="w-[115px] text-xs font-medium py-2"
                        disabled={!productDetails?.product_name || loading || (userData?.role === "org:super_admin" && !productDetails?.org_id)}
                        onClick={() => setIsSaveMode(true)}
                        data-testid="review-button"
                    />
                </div>
            }
        </div>
    </div>;
};

export default ProductProfileModify;
