import React, { useState, useEffect } from 'react';
import MultiSelectSubFilter from './MultiSelectSubFilter';
import { IconSearch } from '@tabler/icons-react';
import Input from '../Input/Input';

const MultiSelectFilter = ({filters: initialFilters, className, categoryFilter, onSelectSubFilter, width, ...props}) => {
    const [selectedFilter, setSelectedFilter] = useState(categoryFilter || null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState(initialFilters);

    const handleSubFilterChange = (updatedData) => {
        // Update the filters state with the new data
        setFilters(prev => {
            const newFilters = {
                ...prev,
                [selectedFilter]: {
                    ...prev[selectedFilter],
                    data: updatedData[selectedFilter]
                }
            };
            
            // Pass the entire filters object to parent
            onSelectSubFilter && onSelectSubFilter(newFilters);
            
            return newFilters;
        });
    };

    return <div data-testid="multi-select-filter" className={`${width ? width : 'w-[100%]'} bg-white rounded-lg shadow-[0_2px_4px_rgba(0,0,0,0.05),0_4px_6px_rgba(0,0,0,0.1)] absolute z-10 top-24 right-2 ${className}`}>
        {
            !selectedFilter ? 
            <React.Fragment>
                <div data-testid="search-container" className="py-2 px-2 border-b border-black-100 mx-2">
                    <Input
                        data-testid="search-input"
                        icon={<IconSearch className='text-black-300' size={16} stroke={2} data-testid="search-icon" />}
                        placeholder="Search tags"
                        aria-label="Search tags"
                        className="w-full bg-transparent border-none gap-[2px] text-sm"
                        inputClassName="w-full"
                        onChange={(value) => {
                            setSearchQuery(value.toLowerCase());
                        }}
                    />
                </div>
                <div data-testid="filters-list" className='flex flex-col py-2'>
                    {
                        Object.keys(filters).filter((filter) => filter?.toLowerCase()?.includes(searchQuery) && !filters[filter]?.hide).length
                        ?
                        Object.keys(filters).filter((filter) => filter?.toLowerCase()?.includes(searchQuery) && !filters[filter]?.hide)?.map((filter, index) => (
                            <div 
                                key={`filter-item-${index}`}
                                data-testid={`filter-item-${index}`}
                                className="flex items-center p-2 cursor-pointer hover:bg-black-50 px-4 gap-2"
                                onClick={() => setSelectedFilter(filter)}
                            >
                                {filters[filter].icon ? filters[filter].icon : null}
                                <span data-testid={`filter-name-${index}`} className="text-sm text-black-400 font-medium">{filter}</span>
                            </div>
                        ))
                        :
                        <div data-testid="no-results-message" className="p-4 text-center text-sm text-black-400">
                            No filters match your search
                        </div>
                    }
                </div>
            </React.Fragment>
            : <React.Fragment>
                <MultiSelectSubFilter 
                    data-testid="multi-select-subfilter"
                    filterTitle={selectedFilter}
                    subFilters={filters[selectedFilter]?.data}
                    icon={filters[selectedFilter].icon}
                    info={filters[selectedFilter]?.info}
                    categoryFilter={categoryFilter}
                    onSelectSubFilter={handleSubFilterChange}
                    onBackButtonClick={() => {
                        setSearchQuery('');
                        setSelectedFilter(null);
                    }}
                />
            </React.Fragment>
        }
    </div>;
};

export default MultiSelectFilter;