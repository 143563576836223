import React from 'react';
import { IconBox } from '@tabler/icons-react';

const NoDataFound = ({ title = "No Data Found", subtitle, icon = <IconBox className="text-black-300" stroke={2} data-testid="no-data-default-icon" />, ...props }) => {
  return (
    <div 
      className="w-60 h-[131px] flex flex-col items-center justify-center mx-auto bg-white p-2 rounded-md"
      data-testid="no-data-container"
      {...props}
    >
      <div 
        className="w-14 h-14 rounded-full bg-black-50 flex flex-col items-center justify-center mb-4"
        data-testid="no-data-icon-container"
      >
        {icon}
      </div>
      <div 
        className="flex flex-col items-center space-y-2 w-full"
        data-testid="no-data-content"
      >
        <h4 
          className="text-black-900 text-xl font-bold text-center"
          data-testid="no-data-title"
        >
          {title}
        </h4>
        {
            subtitle ?
            <p 
              className="text-black-500 text-sm text-center"
              data-testid="no-data-subtitle"
            >
              {subtitle}
            </p>
            : null
        }
      </div>
    </div>
  );
};

export default NoDataFound;