import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const ToolTip = ({ text, position = 'top', datatestid, children, ...props }) => {
  return (
    <div data-testid="tooltip-wrapper" className="inline-block">
      <Tippy 
        content={<div data-testid="tooltip-content">{text}</div>} 
        placement={position} 
        data-testid={datatestid || "tooltip"} 
        allowHTML={true}
        {...props}
      >
        <span data-testid="tooltip-children">
          {children}
        </span>
      </Tippy>
    </div>
  );
};

export default ToolTip;