import React, { useState } from 'react';

const ViewDetails = ({ actData, index, paramMapping }) => {
    const [showDetails, setShowDetails] = useState(false);

    const renderObjectProperties = (obj, itemKey) => {
        if (!obj) return null;
        
        // If this item has a paramMapping, filter the keys
        const keysToShow = (paramMapping && paramMapping[itemKey] && 
                            Array.isArray(paramMapping[itemKey]) && 
                            paramMapping[itemKey].length > 0)
                            ? Object.keys(obj).filter(key => paramMapping[itemKey].includes(key))
                            : Object.keys(obj);
        
        return keysToShow.map(key => (
            <div key={key} className="mb-1">
                <span className="font-semibold capitalize">{key.replace(/_/g, " ")}: </span>
                {obj[key]}
            </div>
        ));
    };

    return <div className='flex flex-col'>
        <p 
            className="text-sm text-cyan-600 mt-1 underline cursor-pointer" 
            data-testid={`activity-details-${index}`}
            onClick={() => setShowDetails(!showDetails)}
        >{showDetails ? 'Hide Details' : 'View Details'}</p>

        {showDetails && (
            <div className="my-2" data-testid="details-container">
                <div className="flex text-xs text-black-700 font-bold bg-black-100">
                    <div className="w-1/3 p-2 font-medium border border-gray-200">Parameter</div>
                    <div className="w-1/3 p-2 font-medium border-b border-t border-r border-gray-200">From</div>
                    <div className="w-1/3 p-2 font-medium border-b border-t border-r border-gray-200">To</div>
                </div>
                {Object.keys(actData)?.map((item, i) => {
                    if (Array.isArray(actData[item].old) || Array.isArray(actData[item].new)) {
                        return (
                            <div key={i} className="flex flex-col text-xs text-black-700" data-testid={`array-item-${i}`}>
                                {[...Array(Math.max(
                                    Array.isArray(actData[item].old) ? actData[item].old.length : 0, 
                                    Array.isArray(actData[item].new) ? actData[item].new.length : 0
                                ))]?.map((_, index) => {
                                    const oldItem = Array.isArray(actData[item].old) && index < actData[item].old.length 
                                        ? actData[item].old[index] 
                                        : null;
                                    const newItem = Array.isArray(actData[item].new) && index < actData[item].new.length 
                                        ? actData[item].new[index] 
                                        : null;
                                    
                                    return (
                                        <div key={index} className="flex border-b border-gray-200" data-testid={`array-item-${i}-row-${index}`}>
                                            <div className="w-1/3 p-2 border-l border-r border-gray-200 capitalize" data-testid={`array-item-${i}-row-${index}-parameter`}>{item.replace(/_/g, " ")} [{index + 1}]</div>
                                            <div className="w-1/3 p-2 border-r border-gray-200" data-testid={`array-item-${i}-row-${index}-from`}>
                                                {   
                                                    oldItem ?
                                                    <div className="text-xs">
                                                        {renderObjectProperties(oldItem, item)}
                                                    </div>
                                                    : "NA"
                                                }
                                            </div>
                                            <div className="w-1/3 p-2 border-r border-gray-200" data-testid={`array-item-${i}-row-${index}-to`}>
                                                {
                                                    newItem ?
                                                    <div className="text-xs">
                                                        {renderObjectProperties(newItem, item)}
                                                    </div>
                                                    : "NA"
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return <div key={i} className="flex text-xs text-black-700" data-testid={`non-array-item-${i}`}>
                            <div className="w-1/3 p-2 border-b border-r border-l border-gray-200 capitalize" data-testid={`non-array-item-${i}-parameter`}>{item.replace(/_/g, " ")}</div>
                            <div className="w-1/3 p-2 border-b border-r border-gray-200 break-words" data-testid={`non-array-item-${i}-from`}>{actData[item].old}</div>
                            <div className="w-1/3 p-2 border-b border-r border-gray-200 break-words" data-testid={`non-array-item-${i}-to`}>{actData[item].new}</div>
                        </div>
                    }
                })}
            </div>
        )}
    </div>;
};

export default ViewDetails;
