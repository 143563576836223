import React from "react";

const Loader = ({abs, className, ...props}) => {
    return (
        <div 
            className={`loading ${abs ? "abs" : ""} ${className || ""}`} 
            data-testid="loader-component"
            {...props}
        />
    );
}

export default Loader;