import React from "react";
import { IconX } from '@tabler/icons-react';
import Button from "../Button/Button";

const AlertBox = ({ title, message, onClose, onConfirm, confirmBtnText, confirmBtnType, cancelBtnType }) => {
    return <div className="fixed top-[0px] left-0 w-full h-full bg-gray-900 bg-opacity-50 z-10 z-[9999]" data-testid="alert-box">
        <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white shadow-md w-[30%] rounded-[8px]`}
            data-testid="alert-box-container"
        >
            <div className="w-full p-4 pb-0">
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold text-gray-900" data-testid="alert-box-title">
                        {title}
                    </h3>
                    <Button 
                        icon={<IconX className="w-5 h-5" />}
                        type="secondary-v2"
                        className="p-2"
                        onClick={onClose}
                        data-testid="alert-box-close-btn"
                    />
                </div>
            </div>
            <div className="h-px bg-black-100 w-full my-2"></div>
            <p className="text-sm text-gray-600 px-4" data-testid="alert-box-message">
                {message}
            </p>
            <div className="flex items-center gap-4 p-4">
                <Button 
                    text="Cancel"
                    type={cancelBtnType}
                    className="w-full py-2"
                    onClick={onClose}
                    data-testid="alert-box-cancel-btn"
                />
                <Button  
                    text={confirmBtnText} 
                    type={confirmBtnType}
                    className="w-full py-2"
                    onClick={onConfirm}
                    data-testid="alert-box-confirm-btn"
                />
            </div>
        </div>
    </div>;
};

export default AlertBox;
