import React from "react";
import { IconSearch } from '@tabler/icons-react';

const Input = ({ className, inputClassName, value, icon, placeholder, onChange, maxLength, type, min, max, error, disabled, ...props }) => {

    return (
        <div className="flex flex-col items-start flex-grow gap-[2px]">
            <div data-testid="input-container" className={`${className || ""} w-[100%] flex-grow bg-black-50 border border-black-100 rounded flex items-center ${disabled ? "cursor-not-allowed" : ""}`} {...props}>
                {icon && <div data-testid="input-icon">{icon}</div>}
                <input
                    data-testid="input-field"
                    type={type || "text"}
                    value={value}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    min={min}
                    max={max}
                    disabled={disabled}
                    className={`${inputClassName || ""} bg-transparent border-none outline-none text-sm text-black-600 ${icon ? "ml-2" : ""} ${disabled ? "cursor-not-allowed" : ""}`}
                    onChange={(e) => {
                        if (onChange && !disabled) {
                            onChange(e.target.value);
                        }
                    }}
                />
            </div>
            {error && <span className="text-xs text-red-500">{error}</span> }
        </div>
    );
};

export default Input;
