import React from "react";
import Tag from "../Tag/Tag";

const ListCard = ({name, type, status, selected, listData, onClick, ...props}) => {
    return (
        <div 
            data-testid="list-card-container" 
            className={`w-full p-3 ${selected ? "border border-cyan-600 bg-cyan-50" : "border border-black-100 bg-white"} rounded-lg hover:bg-cyan-50 cursor-pointer overflow-hidden`} 
            onClick={onClick}
            {...props}
        >
            <div 
                data-testid="list-card-name" 
                className="text-sm font-semibold text-black-900 mb-2 truncate overflow-hidden" 
                title={name}
            >
                {name}
            </div>
            <div data-testid="list-card-content" className="flex flex-col gap-2">
                {
                    Object.keys(listData).map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {
                                    listData[item]?.length > 0 && 
                                    <div data-testid={`list-card-item-${index}`} className="flex items-center gap-2 overflow-hidden">
                                        <span data-testid={`list-card-item-label-${index}`} className="text-xs text-black-500 font-medium">
                                            {item}:
                                        </span>
                                        <div data-testid={`list-card-item-tags-${index}`} className="flex items-center gap-1 truncate">
                                            {
                                                listData[item].slice(0, 2).map((tagItem, tagIndex) => {
                                                    return <Tag 
                                                        key={tagIndex}
                                                        data-testid={`list-card-tag-${index}-${tagIndex}`}
                                                        name={tagItem.name} 
                                                        type="secondary"
                                                        icon={tagItem.color ? <div data-testid={`list-card-tag-icon-${index}-${tagIndex}`} className={`w-[5px] h-[5px] bg-${tagItem.color} rounded-full`} style={{ backgroundColor: tagItem.color }}></div> : tagItem.icon ? tagItem.icon : null}
                                                        className="px-2 py-0.5"
                                                        hoverable={false}
                                                        noWrap={true}
                                                    />
                                                })
                                            }
                                            {listData[item].length > 2 && (
                                                <Tag
                                                    data-testid={`list-card-more-tag-${index}`}
                                                    name={`+${listData[item].length - 2}`}
                                                    type="secondary"
                                                    className="px-2 py-0.5"
                                                    hoverable={false}
                                                />
                                            )}
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default ListCard;
