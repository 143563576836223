import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import ContxtNewLogo from "../../assets/x-logo.svg";
import Logo from "../../assets/logo.png";
import { 
IconFocusCentered, 
IconLocation, 
IconMapPin, 
IconDeviceTabletCheck, 
IconChartArcs3, 
IconBorderSides, 
IconSettings2, 
IconSettings, 
IconHelp,
IconBoxPadding,
IconRoute,
IconPackage,
IconAlertSquareRounded,
IconTag,
IconBuildingSkyscraper,
IconUsersGroup } from '@tabler/icons-react';
import MenuExpand from "./MenuExpand";
import HeaderBar from "./HeaderBar";
import { SignedIn, UserButton } from '@clerk/clerk-react';
import UserTimezone, { DotIcon } from "./UserTimezone";

let menuList = {
  "command_center": "Command Center",
  "lane_performance": "Lane Performance",
  "lane_validation": "Lane Validation",
  "configuration": "Configuration",
  "manage": "Manage",
  "help": "Help"
}

const Header = ({children, ...props}) => {
  const location = useLocation();
  const [headerExpand, setHeaderExpand] = useState(false);
  const [expandMenu, setExpandMenu] = useState(false);
  const [selected, setSelected] = useState("configuration");
  const [menu, setMenu] = useState("Configuration");
  const [subMenuName, setSubMenuName] = useState("Product Profiles");
  const [featureName, setFeatureName] = useState("");

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts.includes('product-profiles')) {
      if (location.state?.productName) {
        setFeatureName(location.state.productName);
      }
    } else {
      setFeatureName("");
    }
  }, [location]);

  return (
    <div data-testid="header-container" className="flex items-start bg-black-50 h-screen w-screen">
      <SignedIn>
        <div data-testid="sidebar" className={`flex flex-col ${headerExpand ? "w-[255px] items-start" : "w-[70px] items-center"} h-full px-3 p-4 bg-white border-r border-black-100`}>
          {
            headerExpand ?
            <img data-testid="full-logo" className="h-[18px] mt-[6px] cursor-pointer px-1 w-fit" src={Logo} alt="contxt_logo"/>
            : <div data-testid="collapsed-logo-container" className="w-full flex justify-center">
              <img data-testid="collapsed-logo" className="h-[20px] mt-[6px] cursor-pointer" src={ContxtNewLogo} alt="logo"/>
            </div>
          }
          <div data-testid="menu-container" className="w-full flex-grow flex flex-col gap-2 mt-[40px]">
            <MenuExpand 
              data-testid="command-center-menu"
              icon={<IconFocusCentered className={`${selected === "command_center" && (!expandMenu || !headerExpand) ? "text-cyan-600 group-hover:text-cyan-600" : headerExpand ? "text-black-400" : "text-black-300"}`} stroke={2} />} 
              menu={"Command Center"}
              submenu={[
                {label: "Live Shipments", icon: <IconLocation className={`text-black-400 ${subMenuName === "Live Shipments" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />},
                {label: "Completed Shipments", icon: <IconMapPin className={`text-black-400 ${subMenuName === "Completed Shipments" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />},
                {label: "Product Release", icon: <IconDeviceTabletCheck className={`text-black-400 ${subMenuName === "Product Release" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />}
              ]}
              headerExpand={headerExpand}
              setHeaderExpand={(flag) => setHeaderExpand(flag)}
              type="command_center"
              selected={selected}
              setSelected={(menu) => setSelected(menu)}
              setSubmenu={(flag) => setExpandMenu(flag)}
              subMenuName={subMenuName}
              setSubMenuName={(name, menu) => {
                setSubMenuName(name);
                setMenu(menuList[menu]);
              }}
            />
            <MenuExpand 
              data-testid="lane-performance-menu"
              icon={<IconChartArcs3 className={`${selected === "lane_performance" && (!expandMenu || !headerExpand) ? "text-cyan-600 group-hover:text-cyan-600" : headerExpand ? "text-black-400" : "text-black-300"}`} stroke={2} />} 
              menu={"Lane Performance"}
              headerExpand={headerExpand}
              setHeaderExpand={(flag) => setHeaderExpand(flag)}
              type="lane_performance"
              selected={selected}
              setSelected={(menu) => setSelected(menu)}
              setSubmenu={(flag) => setExpandMenu(flag)}
              setSubMenuName={(name, menu) => {
                setSubMenuName(name);
                menu && setMenu(menuList[menu]);
              }}
            />
            <MenuExpand 
              data-testid="lane-validation-menu"
              icon={<IconBorderSides className={`${selected === "lane_validation" && (!expandMenu || !headerExpand) ? "text-cyan-600 group-hover:text-cyan-600" : headerExpand ? "text-black-400" : "text-black-300"}`} stroke={2} />} 
              menu={"Lane Validation"}
              headerExpand={headerExpand}
              setHeaderExpand={(flag) => setHeaderExpand(flag)}
              type="lane_validation"
              selected={selected}
              setSelected={(menu) => setSelected(menu)}
              setSubmenu={(flag) => setExpandMenu(flag)}
              setSubMenuName={(name, menu) => {
                setSubMenuName(name);
                menu && setMenu(menuList[menu]);
              }}
            />
            <div data-testid="divider-1" className="h-px bg-black-100 w-full my-2"></div>
            <MenuExpand 
              data-testid="configuration-menu"
              icon={<IconSettings className={`${selected === "configuration" && (!expandMenu || !headerExpand) ? "text-cyan-600 group-hover:text-cyan-600" : headerExpand ? "text-black-400" : "text-black-300"}`} stroke={2} />} 
              menu={"Configuration"}
              submenu={[
                {label: "Product Profile", link: "/product-profiles", icon: <IconBoxPadding className={`text-black-400 ${subMenuName === "Product Profile" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />},
                {label: "Lane Profile", icon: <IconRoute className={`text-black-400 ${subMenuName === "Lane Profile" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />},
                {label: "Shipment Profile", icon: <IconPackage className={`text-black-400 ${subMenuName === "Shipment Profile" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />},
                {label: "Deviation Profile", icon: <IconAlertSquareRounded className={`text-black-400 ${subMenuName === "Deviation Profile" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />},
                {label: "Tags", icon: <IconTag className={`text-black-400 ${subMenuName === "Tags" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />}
              ]}
              headerExpand={headerExpand}
              setHeaderExpand={(flag) => setHeaderExpand(flag)}
              type="configuration"
              selected={selected}
              setSelected={(menu) => setSelected(menu)}
              setSubmenu={(flag) => setExpandMenu(flag)}
              subMenuName={subMenuName}
              setSubMenuName={(name, menu) => {
                setSubMenuName(name);
                menu && setMenu(menuList[menu]);
              }}
            />
            <MenuExpand 
              data-testid="manage-menu"
              icon={<IconSettings2 className={`${selected === "manage" && (!expandMenu || !headerExpand) ? "text-cyan-600 group-hover:text-cyan-600" : headerExpand ? "text-black-400" : "text-black-300"}`} stroke={2} />} 
              menu={"Manage"}
              headerExpand={headerExpand}
              submenu={[
                {label: "Organization", link: `/organizations`, icon: <IconBuildingSkyscraper className= {`text-black-400 ${subMenuName === "Organization" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />},
                {label: "User Group", icon: <IconUsersGroup className={`text-black-400 ${subMenuName === "User Group" ? "text-cyan-600 group-hover:text-cyan-600" : ""}`} stroke={2} />}
              ]}
              setHeaderExpand={(flag) => setHeaderExpand(flag)}
              type="manage"
              selected={selected}
              setSelected={(menu) => setSelected(menu)}
              setSubmenu={(flag) => setExpandMenu(flag)}
              setSubMenuName={(name, menu) => {
                setSubMenuName(name);
                menu && setMenu(menuList[menu]);
              }}
            />
            <div data-testid="divider-2" className="h-px bg-black-100 w-full my-2"></div>
            <MenuExpand 
              data-testid="help-menu"
              icon={<IconHelp className={`${selected === "help" && (!expandMenu || !headerExpand) ? "text-cyan-600 group-hover:text-cyan-600" : headerExpand ? "text-black-400" : "text-black-300"}`} stroke={2} />} 
              menu={"Help"}
              headerExpand={headerExpand}
              setHeaderExpand={(flag) => setHeaderExpand(flag)}
              type="help"
              selected={selected}
              setSelected={(menu) => setSelected(menu)}
              setSubmenu={(flag) => setExpandMenu(flag)}
              subMenuName={subMenuName}
              setSubMenuName={(name, menu) => {
                setSubMenuName(name);
                menu && setMenu(menuList[menu]);
              }}
            />
          </div>
          <UserButton afterSignOutUrl="/" data-testid="user-avatar">
            <UserButton.UserProfilePage label="Timezone" url="timezone" labelIcon={<DotIcon />}>
              <UserTimezone />
            </UserButton.UserProfilePage>
          </UserButton>
        </div>
      </SignedIn>
      <div data-testid="content-container" className="flex flex-col items-start flex-grow h-full overflow-hidden">
        <SignedIn>
          <HeaderBar 
            menu={menu} 
            subMenu={subMenuName}
            feature={featureName}
            headerExpand={headerExpand}
            setHeaderExpand={(flag) => setHeaderExpand(flag)}
          />
        </SignedIn>
        <div data-testid="children-container" className="w-full h-[calc(100vh-64px)] overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Header;
