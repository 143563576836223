import React from "react";
import { 
IconLayoutSidebarFilled, 
IconLayoutSidebar,
IconChevronRight, 
IconBellFilled } from '@tabler/icons-react';

const HeaderBar = ({menu, subMenu, feature, headerExpand, setHeaderExpand, ...props }) => {
  return (<div data-testid="header-bar" className="w-full p-4 flex items-center justify-between bg-black-50">
    <div data-testid="header-bar-left" className="flex items-center">
        {
          headerExpand ? 
          <IconLayoutSidebar data-testid="collapse-sidebar-icon" size={30} className="text-black-600 hover:bg-white p-1 cursor-pointer rounded-md" stroke={2} onClick={() => setHeaderExpand(false)} /> 
          : 
          <IconLayoutSidebarFilled data-testid="expand-sidebar-icon" size={30} className="text-black-600 hover:bg-white p-1 cursor-pointer rounded-md" stroke={2} onClick={() => setHeaderExpand(true)} />
        }
      <div data-testid="header-divider" className="border-l border-black-300 mx-4 h-6"></div>
      <div data-testid="header-menu-title" className="text-sm font-semibold text-black-700">
        {menu}
      </div>
      {
        subMenu && <React.Fragment>
            <IconChevronRight data-testid="submenu-chevron" className="text-gray-400 mx-4 h-5" stroke={2} />
            <div data-testid="header-submenu-title" className="text-sm font-semibold text-black-700">
              {subMenu}
            </div>
        </React.Fragment>
      }
      {
        feature && <React.Fragment>
            <IconChevronRight data-testid="feature-chevron" className="text-gray-400 mx-4 h-5" stroke={2} />
            <div data-testid="header-feature-title" className="text-sm font-semibold text-black-700">
              {feature}
            </div>
        </React.Fragment>
      }
    </div>
    <div data-testid="header-notifications" className="flex justify-end hover:bg-white p-1 cursor-pointer rounded-md">
      <IconBellFilled data-testid="notification-bell" className="text-black-600" stroke={2} size={22}/>
    </div>
  </div>);
};

export default HeaderBar;