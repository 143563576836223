import React, { useState, useEffect, useRef } from "react";
import { IconChevronDown } from '@tabler/icons-react';

const SelectBox = ({ className, value, selectClassName, options, placeholder, fontClassName, defaultIcon, error, disabled, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const selectBoxRef = useRef(null);

    useEffect(() => {
        if (value !== undefined) {
            const option = options.find(opt => opt.value === value);
            if (option) {
                setSelectedOption(option);
            }
        }
    }, [value, options]);

    const handleSelect = (option) => {
        if (disabled) return;
        setSelectedOption(option);
        setIsOpen(false);
        if (props.onChange) {
            props.onChange(option);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div 
            className={`${className || ""} relative`} 
            ref={selectBoxRef} 
            data-testid="selectbox-container"
            {...props}
        >
            <div
                className={`${selectClassName || ""} ${fontClassName || ""} flex-grow bg-black-50 border border-black-100 rounded flex items-center justify-between px-3 py-1 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => !disabled && setIsOpen(!isOpen)}
                data-testid="selectbox-trigger"
            >
                <div className="flex items-center" data-testid="selectbox-selected-content">
                    {(selectedOption?.icon || defaultIcon) && (
                        <span className="mr-2" data-testid="selectbox-selected-icon">
                            {selectedOption?.icon || defaultIcon}
                        </span>
                    )}
                    <span className={`${!selectedOption ? "text-black-300" : fontClassName || "text xs"}`} data-testid="selectbox-selected-text">
                        {selectedOption ? selectedOption.label : placeholder}
                    </span>
                </div>
                <IconChevronDown 
                    className={`w-4 h-4 text-black-600 transition-transform ${isOpen ? 'rotate-180' : ''}`} 
                    stroke={2} 
                    data-testid="selectbox-chevron"
                />
            </div>

            {isOpen && !disabled && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-black-100 rounded shadow-lg" data-testid="selectbox-dropdown">
                    {options.map((option, index) => (
                        <div
                            key={option.value}
                            onClick={() => handleSelect(option)}
                            className={`px-3 py-1 cursor-pointer text-black-900 hover:bg-black-50 rounded-[4px] ${
                                selectedOption?.value === option.value ? 'bg-black-50' : ''
                            }`}
                            data-testid={`selectbox-option-${index}`}
                        >
                            <div className={`flex items-center ${fontClassName || ""}`}>
                                {option.icon && (
                                    <span className="mr-2" data-testid={`selectbox-option-icon-${index}`}>
                                        {option.icon}
                                    </span>
                                )}
                                <span data-testid={`selectbox-option-label-${index}`}>
                                    {option.label}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {error && <span className="text-xs text-red-500">{error}</span> }
        </div>
    );
};

export default SelectBox;