import { useAuth } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { isSignedIn, isLoaded } = useAuth();
  
  // Show loading or nothing while checking auth status
  if (!isLoaded) {
    return null;
  }
  
  // Redirect to home if not signed in
  if (!isSignedIn) {
    return <Navigate to="/" replace />;
  }
  
  // If user is signed in, render the protected content
  return children;
};

export default ProtectedRoute; 