import React, { useState } from "react";
import { IconSearch, 
IconExternalLink,
IconCaretRightFilled,
IconLayoutGridFilled
} from "@tabler/icons-react";
import Input from "../Input/Input";

const SingleSelectFilter = ({className = "", filters = {}, filterType = "", onClick, ...props}) => {
    const [searchTerm, setSearchTerm] = useState("");
    
    return <div data-testid="single-select-filter" className={`w-[290px] bg-white rounded-lg shadow-[0_2px_4px_rgba(0,0,0,0.05),0_4px_6px_rgba(0,0,0,0.1)] absolute z-10 top-7 right-[-200px] ${className}`}>
        <div data-testid="search-input" className="m-2 w-[94%]">
            <Input
              data-testid="search-input-field"
              icon={<IconSearch stroke={2} className="w-4 h-4 text-black-500" />}
              placeholder="Search"
              className="px-3 py-1.5"
              inputClassName="w-full"
              onChange={(value) => {
                setSearchTerm(value.toLowerCase());
              }}
            />
        </div>
        <div data-testid="divider" className="border-t border-black-100 mx-2"></div>
        {
            filters && filterType && filters[filterType]?.data?.map((filter, filterIndex) => {
                if (!filter) return null;
                
                const mainFilterMatches = !searchTerm || filter.filterTitle.toLowerCase().includes(searchTerm);
                
                // If search term exists and main filter doesn't match, check if any subfilters match
                if (searchTerm && !mainFilterMatches) {
                    const hasMatchingSubfilters = Array.isArray(filter.subFilters) && 
                        filter.subFilters.some(subFilter => 
                            subFilter && subFilter.filterTitle.toLowerCase().includes(searchTerm)
                        );
                    
                    // Hide this filter if neither the main filter nor any subfilters match
                    if (!hasMatchingSubfilters) return null;
                }
                
                return <div 
                  key={`filter-${filterIndex}`}
                  data-testid={`filter-option-${filterIndex}`}
                  className="flex items-center justify-between p-2 rounded-md hover:bg-black-50 cursor-pointer group relative"
                >
                    <div className="flex items-center">
                        <IconLayoutGridFilled color={filter.color} data-testid={`icon-${filterIndex}`} className={`w-3 h-3 text-[${filter.color}] rotate-45`} />
                        <span data-testid={`filter-title-${filterIndex}`} className="ml-2 text-sm text-black-400 group-hover:font-semibold">{filter.filterTitle}</span>
                    </div>
                    <IconCaretRightFilled data-testid={`caret-${filterIndex}`} className="w-4 h-4 text-black-400 group-hover:font-semibold" />

                    <div data-testid={`subfilters-${filterIndex}`} className="absolute top-0 right-[-140px] hidden group-hover:block w-[140px] bg-white rounded-lg shadow-[0_2px_4px_rgba(0,0,0,0.05),0_4px_6px_rgba(0,0,0,0.1)] border border-black-50 py-2">
                        {
                            Array.isArray(filter.subFilters) && filter.subFilters.map((subFilter, subIndex) => {
                                if (!subFilter) return null;
                                
                                // Only filter subfilters if main filter doesn't match the search term
                                if (searchTerm && !mainFilterMatches && !subFilter.filterTitle.toLowerCase().includes(searchTerm)) {
                                    return null;
                                }
                                
                                return <div 
                                  key={`subfilter-${filterIndex}-${subIndex}`}
                                  data-testid={`subfilter-${filterIndex}-${subIndex}`}
                                  className="p-2 px-3 rounded-md hover:bg-black-50 cursor-pointer flex items-center gap-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onClick && onClick(subFilter.filterTitle, subFilter.id, filter.color)
                                  }}
                                >
                                    <span data-testid={`color-dot-${filterIndex}-${subIndex}`} className={`rounded-[50%] h-2 w-2`} style={{backgroundColor: filter.color}}></span>
                                    <span data-testid={`subfilter-title-${filterIndex}-${subIndex}`} className="text-sm text-black-400 hover:font-semibold">{subFilter.filterTitle}</span>
                                </div>
                            })
                        }
                    </div>
                </div>;
             })
        }
        <div data-testid="bottom-divider" className="border-t border-black-100 mx-2"></div>
        <div data-testid="manage-container" className={`p-3 py-2 my-1 ${false ? "hover:bg-black-50 cursor-pointer" : ""}`}>
            <button data-testid="manage-tags-button" className="flex items-center text-sm text-cyan-600">
                <IconExternalLink data-testid="external-link-icon" className="w-4 h-4 mr-2" />
                Manage {filterType}
            </button>
        </div>
    </div>;
};

export default SingleSelectFilter;
