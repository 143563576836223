import React from 'react';
import { IconX } from '@tabler/icons-react';

const Tag = ({icon, name, close, type, className, hoverable, noWrap, onClose, ...props}) => {

    const getTagClassName = (type) => {
        switch (type) {
            case "primary":
                return `bg-white text-black-700 ${hoverable ? "hover:bg-black-50" : ""} border border-black-100`;
            case "secondary":
                return `bg-black-100 text-black-500 ${hoverable ? "hover:bg-black-50" : ""} border border-black-100`;
            default:
                return "";
        }
    }
    
    return (
        <div 
            className={`${getTagClassName(type)} ${className || "h-6 px-2"} flex items-center gap-2 rounded text-xs font-medium cursor-pointer ${noWrap ? "whitespace-nowrap overflow-hidden" : ""}`}
            data-testid="tag-container"
            {...props}
        >
            {icon ? <span data-testid="tag-icon">{icon}</span> : null}
            <span className={`${noWrap ? "truncate" : ""}`} data-testid="tag-name" title={name}>{name}</span>
            {close && <IconX className={"w-3 h-3"} stroke={2} data-testid="tag-close-icon" onClick={onClose} />}
        </div>
    );
};

export default Tag;
