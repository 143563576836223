import React,{ useState, useEffect } from 'react';
import SimpleBarReact from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import './SmartScroll.css';

const SmartScroll = ({peerElementClassName, dependency, containerRef, className, ...props}) => {

    const [peerHeight, setPeerHeight] = useState(0);

    useEffect(() => {
        const calculateHeight = () => {
            if (containerRef && containerRef?.current) {
                // Get the height of the elements above the scrollable area
                const peerElementHeight = containerRef.current.querySelector(peerElementClassName)?.clientHeight || 0;
                
                setPeerHeight(peerElementHeight);
            }
        };
        
        // Calculate initially and on window resize
        calculateHeight();
        window.addEventListener('resize', calculateHeight);
        
        return () => window.removeEventListener('resize', calculateHeight);
    }, [dependency]); // Recalculate when filters change

    return(
        <React.Fragment>
            {
                window.screen.width<1024 ?
                <div data-testid="scroll-bar-mobile-view">
                    {props.children}
                </div>
                 :
                <div 
                    className={className} 
                    style={{height: `calc(100% - ${peerHeight}px)`}}
                    data-testid="scroll-bar-container"
                >
                    <SimpleBarReact 
                        id={props.id||''} 
                        style={{ outline: 0 }}
                        className="h-[100%]"
                        data-testid="simple-bar-component"
                    >
                        <div data-testid="scroll-bar-content">
                            {props.children}
                        </div>
                    </SimpleBarReact>
                </div>
            }
        </React.Fragment>
    );
}

export default SmartScroll;