import React, { useRef, useState, useEffect } from "react";
import { IconCircleXFilled } from '@tabler/icons-react';
import ViewDetails from "./ViewDetails";
import SmartScroll from "../SmartScroll/SmartScroll";
import Loader from "../Loader/Loader";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from "moment";

const ActivityPopup = ({ activityData, onActivityClose, loading, userList, onClickOutside, paramMapping, ...props }) => {

    const containerRef = useRef(null);
    const [actData, setActData] = useState([]);

    TimeAgo.addDefaultLocale(en);
    const timeAgo = new TimeAgo('en-US')

    useEffect(() => {
        setActData(activityData);
    }, [activityData]);

    return <div className="fixed top-[0px] left-0 w-full h-full z-10 z-[9999]" data-testid="activity-popup" onClick={onActivityClose}>
        <div
            ref={containerRef}
            className={`fixed top-0 right-0 border-l border-black-100 w-[30%] h-full bg-white shadow-md p-4`}
            data-testid="activity-popup-panel"
        >
            <div className="top-section flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-black-800" data-testid="activity-popup-title">Activity History</h3>
                <button className="text-black-500 hover:text-black-800" onClick={onActivityClose} data-testid="activity-popup-close-btn">
                    <IconCircleXFilled className="w-5 h-5" />
                </button>
            </div>
            <SmartScroll
                containerRef={containerRef}
                peerElementClassName=".top-section"
                className="p-2.5 pt-0 flex-grow h-full overflow-hidden"
                data-testid="activity-popup-scrollbar"
            >
            <div className="space-y-4" data-testid="activity-list">
                { loading && <Loader /> }
                {
                    actData?.map((activity, index) => {
                        let user = userList?.filter(user => user?.publicUserData?.userId === activity.changed_by)[0]?.publicUserData;
                        return <div className="flex items-start gap-3 relative" key={index} data-testid={`activity-item-${index}`}>
                            { actData.length-1 !== index && <div className="w-[2px] h-full bg-black-100 absolute top-[22px] left-[13px]" data-testid={`activity-timeline-${index}`}></div> }
                            <div className="h-[29px] w-[29px] shrink-0 bg-cyan-400 rounded-full flex items-center justify-center text-xs font-medium text-black-900 z-[5] uppercase" data-testid={`activity-avatar-${index}`}>
                                {
                                    user?.hasImage ?
                                    <img alt="user_image" src={user?.imageUrl} className="w-[29px] h-[29px] rounded-full" />
                                    : <span>{activity.changed_by ? activity.changed_by?.split(" ").map(n => n[0]) : 'A'}</span>
                                }
                            </div>
                            <div className="flex-1 w-[100px] max-w-[96%]">
                                <p className="text-sm text-black-700" data-testid={`activity-text-${index}`}>
                                    <span className="font-semibold text-black-900 capitalize" data-testid={`activity-username-${index}`}>{activity.changed_by || 'Anonymous'}</span> {activity.operation === "INSERT" ? "created" : "updated"} the product profile.
                                </p>
                                { activity.operation === "UPDATE" && <ViewDetails actData={activity.changed_columns} index={index} paramMapping={paramMapping} /> }
                                <p className="text-xs text-black-400 mt-1" data-testid={`activity-timestamp-${index}`} title={moment(activity.changed_at).format("DD-MM-YYYY HH:mm:ss")}>{timeAgo.format(new Date(activity.changed_at)) || ''}</p>
                            </div>
                        </div>;
                    })
                }
                </div>
            </SmartScroll>
        </div>
    </div>;
};

export default ActivityPopup;
