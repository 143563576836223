import React from "react";

const Button = ({ icon, text, type, className, disabled, onClick, ref, title, ...props }) => {

    const buttonClass = () => {
        if(type === "primary") { 
            return "bg-cyan-600 text-white hover:bg-cyan-700";
        } else if(type === "primary-v2") {
            return "bg-white text-cyan-600 hover:bg-cyan-50 border border-cyan-600";
        } else if(type === "primary-v3") {
            return "text-cyan-600 hover:bg-cyan-50 border border-transparent"
        } else if(type === "secondary") {
            return "text-black-900 hover:text-cyan-700 hover:bg-black-50 border border-transparent";
        } else if(type === "secondary-v2") {
            return "text-black-300 hover:bg-black-50 border border-transparent";
        } else if(type === "secondary-v3") {
            return "bg-white text-black-700 hover:bg-black-50 border border-black-100";
        } else if(type === "tertiary") {
            return "text-white bg-black-900 hover:bg-black-700";
        } else if(type === "warning") {
            return "bg-red-50 text-black-900 hover:text-white hover:bg-red-600 border border-red-500";
        } else {
            return "";
        }
    };

    return <button 
        ref={ref ? ref : null} 
        className={`${className || ""} ${disabled ? "text-black-300 bg-black-100 cursor-not-allowed" : className} ${!disabled ? buttonClass() : ""} rounded flex items-center justify-center gap-2`} 
        disabled={disabled} 
        onClick={onClick}
        title={title && title}
        data-testid={props['data-testid'] || 'button-component'}
        {...props} 
    >
        {icon ? <span data-testid="button-icon">{icon}</span> : null}
        {text ? <span data-testid="button-text">{text}</span> : null}
    </button>;
};

export default Button;
