import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import App from './App'
import './index.css'
import { ClerkProvider } from '@clerk/clerk-react'
import SignOut from './components/SignOut/SignOut';
import Header from './components/Header/Header';
import ProtectedRoute from './utility/ProtectedRoute.jsx'
import ProductProfile from './components/ProductProfile/ProductProfile';
import Organization from './components/Organization/Organization';
import ErrorBoundary from './utility/ErrorBoundary.jsx'

// Import your Publishable Key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('Add your Clerk Publishable Key to the .env file')
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} fallbackRedirectUrl="/">
      <BrowserRouter>
        <ErrorBoundary>
          <Header>
            <Routes>
              <Route
                path="/product-profiles"
                element={
                  <ProtectedRoute>
                    <ProductProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/product-profiles/:productId?"
                element={
                  <ProtectedRoute>
                    <ProductProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/organizations"
                element={
                  <ProtectedRoute>
                    <Organization />
                  </ProtectedRoute>
                }
              />
              <Route path="/sign-out" element={<SignOut />} />
              <Route path="/" element={<App />} />
            </Routes>
          </Header>
        </ErrorBoundary>
      </BrowserRouter>      
    </ClerkProvider>
  </React.StrictMode>
);