import { useClerk } from '@clerk/clerk-react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function SignOut() {
    const { signOut } = useClerk();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const performSignOut = async () => {
            try {
                await signOut();
                // Redirect to sign-in page after successful sign-out
                navigate({pathname: `/`, search: ''})
            } catch (error) {
                console.error('Error signing out:', error);
                // If there's an error, still try to redirect
                navigate({pathname: `/`, search: ''})
            }
        };

        performSignOut();
    }, [signOut, location]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50" data-testid="sign-out-page">
            <div className="p-8 text-center">
                <h1 className="text-2xl font-semibold mb-4">Signing you out...</h1>
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent"></div>
            </div>
        </div>
    );
} 