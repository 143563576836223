import React from 'react';
import moment from 'moment';
import { createClient } from '@supabase/supabase-js';

export const createClerkSupabaseClientProduct = (session) => {

    return createClient(
        import.meta.env.VITE_SUPABASE_PRODUCT_URL,
        import.meta.env.VITE_SUPABASE_PRODUCT_KEY,
        {
            global: {
                // Get the custom Supabase token from Clerk
                fetch: async (url, options = {}) => {
                    const clerkToken = await session?.getToken({
                        template: import.meta.env.VITE_CLERK_PRODUCT_JWT_TEMPLATE,
                    })
                    // Insert the Clerk Supabase token into the headers
                    const headers = new Headers(options?.headers)
                    headers.set('Authorization', `Bearer ${clerkToken}`)
                    // Now call the default fetch
                    return fetch(url, {
                        ...options,
                        headers,
                    })
                },
            },
        },
    )
}

export const createClerkSupabaseClientLandingZone = (session) => {

    return createClient(
        import.meta.env.VITE_SUPABASE_LANDING_ZONE_URL,
        import.meta.env.VITE_SUPABASE_LANDING_ZONE_KEY,
        {
            global: {
                // Get the custom Supabase token from Clerk
                fetch: async (url, options = {}) => {
                    const clerkToken = await session?.getToken({
                        template: import.meta.env.VITE_CLERK_LANDING_ZONE_JWT_TEMPLATE,
                    })
                    // Insert the Clerk Supabase token into the headers
                    const headers = new Headers(options?.headers)
                    headers.set('Authorization', `Bearer ${clerkToken}`)
                    // Now call the default fetch
                    return fetch(url, {
                        ...options,
                        headers,
                    })
                },
            },
        },
    )
}

export const getFormattedDuration = (MS, returnString) => {
    //console.log('================: ', ms);
    if(MS === "NA" || MS === null || MS === undefined || isNaN(MS)) return 'NA';
    const ms = Math.abs(MS);
    const diff = moment.duration(ms)._data;
    //console.log('ms: ', ms, diff, t, diff.seconds);
    let str = '';
    if (diff.years > 0) {
        str = `${diff.years} year${diff.years !== 1 ? 's' : ''} ${diff.months > 0 ? `and ${diff.months} month${diff.months !== 1 ? 's' : ''}` : ''}`;
    } else if (diff.months > 0) {
        str = `${diff.months} month${diff.months !== 1 ? 's' : ''} ${diff.days > 0 ? `and ${diff.days} day${diff.days !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.days > 0) {
        str = `${diff.days} day${diff.days !== 1 ? 's' : ''} ${diff.hours > 0 ? `and ${diff.hours + (diff.minutes>30 ? 1 : 0)} hour${diff.hours !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.hours > 0) {
        str = `${diff.hours} hour${diff.hours !== 1 ? 's' : ''} ${diff.minutes > 0 ? `and ${diff.minutes} min${diff.minutes !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.minutes > 0) {
        str = `${diff.minutes} min${diff.minutes !== 1 ? 's' : ''}`;
    } else {
        str = `${diff.seconds > 0 ? '1 min' : '0 mins'}`;
    }

    if(returnString) return str;
    return <span title={`${diff.years>0?`${diff.years}year${diff.years===1?'':'s'}`:''}${diff.months>0?` ${diff.months}month${diff.months===1?'':'s'}`:''}${diff.days>0?` ${diff.days}day${diff.days===1?'':'s'}`:''}${diff.hours>0?` ${diff.hours}hour${diff.hours===1?'':'s'}`:''}${diff.minutes>0?` ${diff.minutes}minute${diff.minutes===1?'':'s'}`:''}${diff.seconds>0?` ${diff.seconds}second${diff.seconds===1?'':'s'}`:''}`}>{str}</span>;
};

export const getRangeLabel = (range_min, range_max) => {
    if((range_min === 0 || range_min) && (range_max === 0 || range_max) && range_min === range_max){
        return `[= ${range_min}]`;
    } else if((range_min === 0 || range_min) && (range_max === 0 || range_max)){
        return `[${range_min} to ${range_max}]`;
    } else if((range_min === null || range_min === undefined) && (range_max === 0 || range_max)){
        return `[< ${range_max}]`;
    } else if((range_min === 0 || range_min) && (range_max === null || range_max === undefined)){
        return `[> ${range_min}]`;
    } else {
        return ``;
    }
}

export const getExcludeRangeLabel = (range_min, range_max) => {
    if((range_min === 0 || range_min) && (range_max === 0 || range_max) && range_min === range_max){
        return `= ${range_min}`;
    } else if((range_min === 0 || range_min) && (range_max === 0 || range_max)){
        return `${range_min} to ${range_max}`;
    } else if((range_min === null || range_min === undefined) && (range_max === 0 || range_max)){
        return `Above ${range_max}`;
    } else if((range_min === 0 || range_min) && (range_max === null || range_max === undefined)){
        return `Below ${range_min}`;
    } else {
        return ``;
    }
}