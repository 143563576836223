import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
IconPlus,
IconTemperature,
IconSearch,
IconFilter } from '@tabler/icons-react';
import SelectedFilter from "../Filter/SelectedFilters";
import ListCard from "../ListCard/ListCard";
import Button from "../Button/Button";
import Input from "../Input/Input";
import SmartScroll from "../SmartScroll/SmartScroll";
import MultiSelectFilter from "../Filter/MultiSelectFilter";
import NoDataFound from "../NoDataFound/NoDataFound";
import Loader from "../Loader/Loader";

const ProductProfileList = ({ filters: initialFilters, productList, onAddProduct, selectedProductId, onSelectProduct, addProduct, loading, onSearchProduct, onFilterProduct, organizationList, editView, userData, clearSearchAndFilters, ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const containerRef = useRef(null);
    const filterRef = useRef(null);
    
    // Initialize filters state
    const [filters, setFilters] = useState({});
    const [checkedFilters, setCheckedFilters] = useState({});
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filterIdCounter, setFilterIdCounter] = useState(0);
    const [searchName, setSearchName] = useState("");

    // Update filters when parent sends new initialFilters
    useEffect(() => {
        if (initialFilters && Object.keys(initialFilters).length > 0) {
            setFilters(initialFilters);
        }
    }, [initialFilters]);

    useEffect(() => {
        if(clearSearchAndFilters) {
            console.log("clearSearchAndFilters", clearSearchAndFilters);
            setSearchName("");
            setSelectedFilters([]);
            productFilterApiData([], true);
        }
    }, [clearSearchAndFilters]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterDropdown(false);
            }
        };
        
        if (filterDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        if(!filterDropdown && checkIfAnyChecked(checkedFilters)) {
            createSelectedFiltersArray(checkedFilters);
            setCheckedFilters({});
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterDropdown]);

    const handleSubFilterChange = (updatedData, filterId) => {
        // Update the filters state with the new data
        if(!filterId) {
            setCheckedFilters(updatedData);
        } else {
            updateSelectedFiltersInstance(updatedData, filterId);
        }
    };

    const updateSelectedFiltersInstance = (updatedFilter, filterId) => {
        if (checkIfAnyChecked(updatedFilter)) {
            // Find the existing filter object by filterId
            const filterIndex = selectedFilters.findIndex(f => f.id === filterId);
            if (filterIndex !== -1) {
                const updatedSelectedFilters = [...selectedFilters];
                const filterObj = { ...updatedSelectedFilters[filterIndex] };

                // Update the filterInstance with new values
                filterObj.filterInstance = updatedFilter;

                // Get selected IDs and colors based on category
                const selectedIds = [];
                const selectedNames = [];
                let colors = [];

                Object.entries(updatedFilter).forEach(([categoryId, categoryData]) => {
                    if (categoryId === "Organizations") {
                        const selectedOrgs = categoryData.data.filter(org => org.checked);
                        selectedIds.push(...selectedOrgs.map(org => org.id));
                        selectedNames.push(...selectedOrgs.map(org => org.filterTitle));
                    } else if (categoryId === "Tags") {
                        categoryData.data.forEach(group => {
                            if (group.subFilters) {
                                // Handle items with subFilters
                                const selectedTags = group.subFilters.filter(tag => tag.checked);
                                selectedIds.push(...selectedTags.map(tag => tag.id));
                                selectedNames.push(...selectedTags.map(tag => tag.filterTitle));
                                colors.push(...selectedTags.map(tag => tag.color));
                            } else if (group.checked) {
                                // Handle items without subFilters
                                selectedIds.push(group.id);
                                if (group.color) colors.push(group.color);
                            }
                        });
                    }
                });

                // Update the filter object
                filterObj.selectedFilterId = selectedIds;
                filterObj.selectedFilterName = selectedNames;
                if(selectedIds.length > 1){
                    if(filterObj.filteringType === "is"){
                        filterObj.filteringType = "is any of";
                    } else if(filterObj.filteringType === "include"){
                        filterObj.filteringType = "include any of";
                    } else if(filterObj.filteringType === "exclude"){
                        filterObj.filteringType = "exclude if any of";
                    }
                } else {
                    if(filterObj.filteringType === "is any of"){
                        filterObj.filteringType = "is";
                    } else if(filterObj.filteringType === "include any of"){
                        filterObj.filteringType = "include";
                    } else if(filterObj.filteringType === "exclude if any of"){
                        filterObj.filteringType = "exclude";
                    }
                }
                if (filterObj.category === "Tags") {
                    filterObj.colors = colors;
                }

                // Update the filter in the array
                updatedSelectedFilters[filterIndex] = filterObj;
                setSelectedFilters(updatedSelectedFilters);
                productFilterApiData(updatedSelectedFilters);
            }
        } else {
            let updatedSelectedFilters = selectedFilters?.filter(f => f.id !== filterId);
            setSelectedFilters(updatedSelectedFilters);
            productFilterApiData(updatedSelectedFilters);
        }
    }

    const productFilterApiData = (filters, avoidApiCall) => {
        const productFilterData = filters?.map(filter => {
            return {
                filterId: filter.id,
                category: filter.category,
                filteringType: filter.filteringType,
                selectedFilterId: filter.selectedFilterId,
            }
        });
        if(avoidApiCall) return;
        onFilterProduct(searchName, productFilterData);
    }

    const checkIfAnyChecked = (filters) => {
        if(Object.keys(filters).length > 0) {
            return Object.values(filters).some(category => 
                category.data.some(item => {
                    // If item has subFilters, check within subFilters
                if (item.subFilters) {
                    return item.subFilters.some(subItem => subItem.checked);
                }
                // If no subFilters, check the item directly
                    return item.checked;
                })
            );
        } else {
            return false;
        }
    }

    const createSelectedFiltersArray = (filters) => {
        // Initialize array to store selected filters
        const sFilters = [];

        // Process each main filter category (Organizations, Tags, etc.)
        Object.entries(filters).forEach(([categoryId, categoryData]) => {
            const selectedIds = [];
            const selectedNames = [];

            // Handle Organizations filter type
            if (categoryId === "Organizations") {
                const selectedOrgs = categoryData.data.filter(org => org.checked);
                if (selectedOrgs.length > 0) {
                    selectedIds.push(...selectedOrgs.map(org => org.id));
                    selectedNames.push(...selectedOrgs.map(org => org.filterTitle));
                }
            }
            // Handle Tags filter type
            else if (categoryId === "Tags") {
                // Check all groups including ungrouped tags
                categoryData.data.forEach(group => {
                    if (group.subFilters) {
                        const selectedTags = group.subFilters.filter(tag => tag.checked);
                        selectedIds.push(...selectedTags.map(tag => tag.id));
                        selectedNames.push(...selectedTags.map(tag => tag.filterTitle));
                    }
                });
            }

            // If there are selected items in this category, add to sFilters
            if (selectedIds.length > 0) {
                sFilters.push({
                    id: `fid-${getFilterId()}`,
                    selectedFilterId: selectedIds,
                    selectedFilterName: selectedNames,
                    icon: categoryData.icon,
                    shortName: categoryId === "Organizations" ? "Org" : categoryId,
                    category: categoryId,
                    // Add colors array for Tags category
                    ...(categoryId === "Tags" && { 
                        colors: categoryData.data.flatMap(item => item.subFilters?.filter(sf => sf.checked) || []).map(tag => tag.color)
                    }),
                    filterType: categoryId === "Organizations" ? "1" : "2",
                    filteringType: selectedIds.length > 1 ? (categoryId === "Organizations" ?  "is any of" : "include any of") : (categoryId === "Organizations" ?  "is" : "include"),
                    filterInstance: filters
                });
            }
        });

        // Update the selected filters state
        let updatedSelectedFilters = [...selectedFilters, ...sFilters];
        setSelectedFilters(updatedSelectedFilters);
        productFilterApiData(updatedSelectedFilters);
    };

    const getFilterId = () => {
        setFilterIdCounter(filterIdCounter + 1);
        return filterIdCounter;
    };

    return (
        <React.Fragment>
            <div ref={containerRef} className="flex flex-col h-full" data-testid="product-profile-list-container">
                <div className="top-section" data-testid="top-section">
                    <div className="p-2.5 pt-3 w-full flex flex-col gap-2 relative" data-testid="actions-section">
                        <Button 
                            icon={<IconPlus className={`w-5 h-5 ${addProduct || organizationList.length === 0 || editView || !userData?.permissions?.includes("org:product_profiles:manage") ? "text-black-300" : "text-white"}`} stroke={2} />} 
                            text="New Product Profile" 
                            type="primary"
                            className="w-full py-2"
                            onClick={onAddProduct}
                            disabled={addProduct || organizationList.length === 0 || editView || !userData?.permissions?.includes("org:product_profiles:manage")}
                            data-testid="add-product-button"
                        />
                        <div className="flex gap-2" data-testid="search-filter-section">
                            <Input 
                                icon={<IconSearch stroke={2} className="w-4 h-4 text-black-300" />}
                                disabled={organizationList.length === 0}
                                placeholder="Search Product Profile"
                                className="py-1.5 px-3"
                                inputClassName="w-full"
                                data-testid="search-input"
                                value={searchName}
                                onChange={(name) => {
                                    setSearchName(name);
                                    const productFilterData = selectedFilters?.map(filter => {
                                        return {
                                            filterId: filter.id,
                                            category: filter.category,
                                            filteringType: filter.filteringType,
                                            selectedFilterId: filter.selectedFilterId,
                                        }
                                    });
                                    onSearchProduct(name, productFilterData);
                                }}
                            />
                            <div className="flex" ref={filterRef} data-testid="filter-container">
                                <Button 
                                    icon={<IconFilter className="w-5 h-5 text-cyan-600" stroke={2} />}
                                    type="primary-v2"
                                    className="py-1 px-2"
                                    onClick={() => setFilterDropdown(!filterDropdown)}
                                    data-testid="filter-button"
                                />
                                { 
                                    filterDropdown && 
                                    <MultiSelectFilter 
                                        filters={filters} 
                                        data-testid="filter-dropdown" 
                                        onSelectSubFilter={handleSubFilterChange}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    { 
                        selectedFilters.length > 0 && 
                        <SelectedFilter 
                            selectedFiltersList={selectedFilters}
                            clearAll={() => {
                                setFilters(filters);
                                setSelectedFilters([]);
                                productFilterApiData([]);
                            }}
                            data-testid="selected-filters"
                            setSelectedFilters={(updatedFilters) => {
                                setSelectedFilters(updatedFilters);
                                productFilterApiData(updatedFilters);
                            }}
                            onFilterChange={(updatedFilter, filterId) => handleSubFilterChange(updatedFilter, filterId)}
                        /> }
                    <div data-testid="product-divider-1" className="h-px mx-2.5 bg-black-100 my-[4px]"></div>
                    <div className="text-xs font-bold text-black-900 px-2.5 my-2" data-testid="product-count">
                        Your products ({productList.length || 0})
                    </div>
                </div>
                {
                    productList.length > 0 ?
                    <SmartScroll 
                        containerRef={containerRef}
                        peerElementClassName=".top-section"
                        dependency={filterDropdown}
                        className="p-2.5 pt-0 flex-grow h-full overflow-hidden"
                        data-testid="product-list-scrollbar"
                    >
                        <div className="space-y-2 relative pb-1" data-testid="product-list">
                            { loading && <Loader /> }
                            {
                                productList.map((product, index) => {
                                    return <ListCard
                                        key={product.id}
                                        name={product.product_name}
                                        listData={{
                                            "Tags": product?.assigned_tags?.length ? product.assigned_tags?.map(tag => ({ name: tag.tag_name, color: tag.color_code })) : [{ name: "---" }],
                                            "STR": product?.str_range_name ? [{ name: product?.str_range_name }, { name: `${product?.str_range_min} to ${product?.str_range_max} °C`, icon: <IconTemperature size={12} stroke={2} className="text-black-500" /> }] : [{ name: "---" }, { name: "---" }]
                                        }}
                                        selected={addProduct ? index === 0 : selectedProductId === product.id}
                                        onClick={() => onSelectProduct(product.id)}
                                        data-testid={`product-item-${product.id}`}
                                    />
                                })
                            }
                        </div>
                    </SmartScroll>
                    : 
                    <div className="flex flex-1 flex-col justify-center items-center" data-testid="empty-state">
                        <NoDataFound 
                            title={searchName || selectedFilters.length ? "No data found" : "Add a new product"} 
                            subtitle={searchName && selectedFilters.length ? "Try different search and filter criteria" : searchName ? "Try different search criteria" : selectedFilters.length ? "Try different filter criteria" :  "Start by adding your first product"}
                            data-testid="no-data-found"
                        />
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default ProductProfileList;
