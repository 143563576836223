import React, { useState, useRef, useEffect } from "react";
import { IconX } from "@tabler/icons-react";
import SelectList from "../SelectList/SelectList";
import Filter from "./MultiSelectFilter";

const filterTypeList = {
    "1": [
        {label: "is any of"},
        {label: "is not"},
    ],
    "2": [
        {label: "include all of"},
        {label: "include any of"},
        {label: "exclude if any of"},
        {label: "exclude if all"},
    ]
}

const singleFilterTypeList = {
    "1": [
        {label: "is"},
        {label: "is not"},
    ],
    "2": [
        {label: "include"},
        {label: "exclude"},
    ]
}

const FilterCard = ({ icon, shortName, filterType, category, className, onClose, selectedFilterId, color, mainFiltersData, onFilterChange, filteringType, changeFilteringType, selectedFilterName, ...props }) => {

    const filterTypeRef = useRef(null);
    const mainFilterRef = useRef(null);

    const [filterTypeOpen, setFilterTypeOpen] = useState(false);
    const [selectedFilterType, setSelectedFilterType] = useState(
        selectedFilterId.length > 1 
            ? (filterTypeList[filterType]?.filter(f => f.label === filteringType)[0]?.label || filterTypeList[filterType]?.[0]?.label || 'is any of') 
            : (singleFilterTypeList[filterType]?.filter(f => f.label === filteringType)[0]?.label || singleFilterTypeList[filterType]?.[0]?.label || 'is')
    );
    const [showMainFilter, setShowMainFilter] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterTypeRef.current && !filterTypeRef.current.contains(event.target)) {
                setFilterTypeOpen(false);
            }
            if (mainFilterRef.current && !mainFilterRef.current.contains(event.target) && 
                !event.target.closest('.filter-component')) {
                setShowMainFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(filteringType !== selectedFilterType){
            setSelectedFilterType(
                selectedFilterId.length > 1 
                    ? (filterTypeList[filterType]?.filter(f => f.label === filteringType)[0]?.label || filterTypeList[filterType]?.[0]?.label || 'is any of') 
                    : (singleFilterTypeList[filterType]?.filter(f => f.label === filteringType)[0]?.label || singleFilterTypeList[filterType]?.[0]?.label || 'is')
            );
        }
    }, [filteringType])

    return (
        <div className="flex-1 max-w-[100%] flex items-center py-0.5 px-1 bg-white border border-black-100 rounded text-xs font-medium cursor-pointer" data-testid="filter-card">
            <div className="w-[30%] flex items-center justify-center rounded-l px-1.5 gap-1 border-r border-black-100" data-testid="filter-icon-section">
                {icon ? icon : null}
                <span className="text-xs text-black-500" data-testid="filter-short-name">{shortName}</span>
            </div>
            <div className="w-[30%] flex items-center justify-center border-r border-black-100 relative" ref={filterTypeRef} data-testid="filter-type-dropdown">
                <span 
                    className="w-[100%] text-center text-xs text-slate-300 px-2.5 py-0.5 hover:bg-black-50 rounded-[2px] truncate"
                    onClick={() => setFilterTypeOpen(!filterTypeOpen)}
                    data-testid="filter-type-selector"
                    title={selectedFilterType}
                >{selectedFilterType}</span>
                { 
                    filterTypeOpen && 
                    <SelectList 
                        selectList={selectedFilterId.length > 1 ? filterTypeList[filterType] : singleFilterTypeList[filterType]} 
                        onClick={(label) => {
                            setSelectedFilterType(label);
                            changeFilteringType(label);
                            setFilterTypeOpen(false);
                        }}
                        data-testid="filter-type-options"
                    /> 
                }
            </div>
            <div 
                className="w-[30%] flex items-center justify-center gap-1 hover:bg-black-50 rounded-[2px] px-1.5 py-0.5 border-r border-black-100 relative" 
                ref={mainFilterRef} 
                data-testid="main-filter-section"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowMainFilter(!showMainFilter)
                }}
            >
                <div className="flex items-center" data-testid="filter-dots">
                    <div className={`w-[5px] h-[5px] bg-${color?.length && color[0]} rounded-full`} style={{backgroundColor: color?.length && color[0] || "#2D40BE"}}/>
                    {selectedFilterId.length === 2 && <div className={`w-[5px] h-[5px] bg-${color?.length && color[1]} rounded-full -ml-[0.5px]`} style={{backgroundColor: color?.length && color[1] || "#2D40BE"}}/>}
                    {(selectedFilterId.length === 3 || selectedFilterId.length > 3) && (
                        <>
                            <div className={`w-[5px] h-[5px] bg-${color?.length && color[1]} rounded-full -ml-[0.5px]`} style={{backgroundColor: color?.length && color[1] || "#2D40BE"}}/>
                            <div className={`w-[5px] h-[5px] bg-${color?.length && color[2]} rounded-full -ml-[0.5px]`} style={{backgroundColor: color?.length && color[2] || "#2D40BE"}}/>
                        </>
                    )}
                </div>
                <span className="text-xs text-black-500 truncate" data-testid="category-button" title={selectedFilterId.length > 1 ? category : selectedFilterName[0]}>
                    {selectedFilterId.length > 1 ? category : selectedFilterName[0]}
                </span>
                {
                    showMainFilter && 
                    <div onClick={(e) => e.stopPropagation()}>
                        <Filter 
                            filters={mainFiltersData} 
                            className="filter-component absolute top-[24px] left-[0px]" 
                            categoryFilter={category} data-testid="main-filter-dropdown" 
                            onSelectSubFilter={onFilterChange}
                            width={'w-[290px]'}
                        />
                    </div>
                }
            </div>
            <div 
                className="w-[10%] flex items-center justify-center hover:bg-black-50 rounded-[2px] px-1 py-0.5"
                onClick={() => onClose && onClose()}
                data-testid="filter-close-button"
            >
                <IconX size={14} className="text-slate-300 cursor-pointer" stroke={2} />
            </div>
        </div>
    );
};

export default FilterCard;