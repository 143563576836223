import React, { Component } from 'react'
import { useAuth } from '@clerk/clerk-react'
import Button from '../components/Button/Button'

// Functional component for the error UI
const ErrorFallbackUI = React.memo(({ error, onReset, onLogout }) => {
  const { signOut } = useAuth()

  const handleLogout = async () => {
    try {
      await signOut()
      // After successful sign out, redirect to sign-in
      window.location.href = '/'
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="text-center p-8 bg-white rounded-lg shadow-lg w-[30%]">
        <h2 className="text-2xl font-bold mb-4">
          Oops! Something went wrong
        </h2>
        <div className="text-gray-600 mb-6">
          {error?.message || 'An unexpected error occurred'}
        </div>
        <div className="flex justify-between gap-8">
          <Button
            type="primary"
            className="px-4 py-2 flex-1"
            onClick={onReset}
            text="Reload"
          />
          <Button
            type="warning"
            className="px-4 py-2 flex-1"
            onClick={handleLogout}
            text="Logout"
          />
        </div>
      </div>
    </div>
  )
})

ErrorFallbackUI.displayName = 'ErrorFallbackUI'

class ErrorBoundaryClass extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo)
  }

  handleReset = () => {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <ErrorFallbackUI
            error={this.state.error}
            onReset={this.handleReset}
          />
        )
      )
    }

    return this.props.children
  }
}

export default ErrorBoundaryClass